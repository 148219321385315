import './App.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Navigation from './navigation';
import IconUpload from './admin/iconUpload';
import AddDefaultOption from './admin/addDefaultOption';
import AddEntry from './user/addEntry';
import Stats from './user/stats'; 
import Feed from './user/feed';
import Calender from './user/calender';
import AdvancedStats from './user/advancedStats';
import ManageIcons from './admin/manageIcons';
import ManageOptions from './admin/manageOptions';
import ManageTags from './admin/manageTags';
import More from './admin/more';
import ManageGoals from './admin/manageGoals';
import AddGoal from './admin/addGoal';
import AddEditGoal from './admin/addGoal';
import AddEditDefaultGoal from './admin/addEditDefaultGoal';
import AddTemplate from './admin/addTemplate';
import ManageTemplates from './admin/manageTemplates';
import ManageReminders from './admin/manageReminders';
import AddEditReminder from './admin/addEditReminder';
import AddEditHabit from './admin/addEditHabit';
import ManageHabits from './admin/manageHabits';
import AddEditIconCategory from './admin/addEditIconCategory';
import ManageIconCategories from './admin/manageIconCategories';
import ManageGoalCategories from './admin/manageGoalCategories';
import AddEditGoalCategory from './admin/addEditGoalCategory';
import AddEditHabitCategory from './admin/addEditHabitCategory';
import ManageHabitCategories from './admin/manageHabitCategories';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route path="icon/:id" element={<IconUpload />} />
          <Route index path="icon" element={<IconUpload />} />
          <Route path="manage-icons" element={<ManageIcons />} />
          <Route path="option" element={<AddDefaultOption />} />
          <Route path="option/:id" element={<AddDefaultOption />} />
          <Route path="manage-options" element={<ManageOptions />} />
          <Route path="manage-user-options" element={<ManageOptions type="user" />} />
          <Route path="entry" element={<AddEntry />} />
          <Route path="entry/:id" element={<AddEntry />} />
          <Route path="stats" element={<Stats />} />
          <Route path="advanced-stats" element={<AdvancedStats />} />
          <Route path="feed" element={<Feed />} />
          <Route path="calender" element={<Calender />} />
          <Route path="manage-tags" element={<ManageTags />} />
          <Route path="more" element={<More />} />
          
          <Route path="add-default-goal" element={<AddEditDefaultGoal />} />
          <Route path="add-default-goal/:id" element={<AddEditDefaultGoal />} />
          <Route path="template" element={<AddTemplate />} />
          <Route path="default-template" element={<AddTemplate templateType="default" isAdmin={true} />} />
          <Route path="template/:id" element={<AddTemplate isAdmin={true} />} />
          <Route path="user-default-template/:id" element={<AddTemplate isAdmin={false} />} />
          
          <Route path="templates" element={<ManageTemplates  isAdmin={true} />} />
          <Route path="user-default-templates" element={<ManageTemplates isAdmin={false} />} />
          
          <Route path="reminder" element={<AddEditReminder />} />
          <Route path="reminder/:id" element={<AddEditReminder />} />
          <Route path="reminders" element={<ManageReminders />} />

          <Route path="icon-category" element={<AddEditIconCategory />} />
          <Route path="icon-category/:id" element={<AddEditIconCategory />} />
          <Route path="icon-categories" element={<ManageIconCategories />} />

          <Route path="habit" element={<AddEditHabit />} />
          <Route path="habit/:id" element={<AddEditHabit />} />
          <Route path="habit/:id/:date" element={<AddEditHabit />} />
          <Route path="habits" element={<ManageHabits />} />


          <Route path="add-default-habit" element={<AddEditHabit isDefault={true} />} />

          <Route path="goal-category" element={<AddEditGoalCategory />} />
          <Route path="goal-category/:id" element={<AddEditGoalCategory />} />
          <Route path="goal-categories" element={<ManageGoalCategories />} />


          <Route path="habit-category" element={<AddEditHabitCategory />} />
          <Route path="habit-category/:id" element={<AddEditHabitCategory />} />
          <Route path="habit-categories" element={<ManageHabitCategories />} />

          <Route path="goal" element={<AddEditGoal />} />
          <Route path="goal/:id" element={<AddEditGoal />} />
          <Route path="goal/:id/:date" element={<AddEditGoal />} />
          <Route path="default-goal" element={<AddEditGoal isManageDefaultGoalView="true" />} />
          <Route path="default-goal/:id" element={<AddEditGoal isManageDefaultGoalView="true" />} />
          <Route path="manage-goals" element={<ManageGoals />} />
          <Route path="manage-default-goals" element={<ManageGoals isManageDefaultGoalView="true" />} />
        </Route>
      </Routes>
    </Router>
  );
}
