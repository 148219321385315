import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { Component }  from 'react';
import IconList from '../admin/iconList';

function AddUserOption(props) {
    const [selectedIconId, setSelectedIconId] = useState("")
    const [name, setName] = useState("")

    const noIconCategories = ["feelings", "social-interaction", "symptoms", "medications", "drugs"]

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options`;
        const data = {
            name: name,
            category: props.optionCategory,
            type: "user"
        };
        if (selectedIconId && typeof selectedIconId === 'number') {
            data.icon_id = selectedIconId
        }
        axios
            .post(url, data, {})
            .then((res) => {
                props.completionHandler();
                setName("")
                setSelectedIconId("")
            }, error => {
                alert(error);
            });
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Option
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>
                    {noIconCategories && !noIconCategories.includes(props.optionCategory) &&
                        <IconList onSelectedIconChange={(iconId) => { setSelectedIconId(iconId) }}/>
                    }
                    <Button variant="primary" type="button" onClick={onSubmitHandler}>
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddUserOption;