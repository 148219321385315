import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { Component }  from 'react';

function AddCustomSection(props) {
    const [name, setName] = useState("")
    const [fieldType, setFieldType] = useState("options")

    useEffect(() => {
        
    }, []);

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/custom_sections`;
        const data = {
            name: name,
            field_type: fieldType === "options_no_icon" ? "options" : fieldType,
            enable_icon_selection: fieldType !== "options_no_icon"
        };
        axios
            .post(url, data, {})
            .then((res) => {
                props.completionHandler();
            }, error => {
                alert(error);
            });
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Option
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={name} onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Field Type</Form.Label>
                        <Form.Select aria-label="" onChange={(event) => { setFieldType(event.target.value) }} value={fieldType}>
                            <option key="options" value="options">Icon + name</option>
                            <option key="options-name-only" value="options_no_icon">Name Only</option>
                            <option key="rating" value="rating">Rating</option>
                        </Form.Select>
                    </Form.Group>
                    <Button variant="primary" type="button" onClick={onSubmitHandler}>
                        Create
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCustomSection;