import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import React, { Component }  from 'react';
import { useNavigate } from "react-router-dom"
import * as dayjs from "dayjs";
import ReactDatePicker from 'react-datepicker';

function ManageGoals(props) {
    const navigate = useNavigate()
    const [displayToast, enableToastDisplay] = useState(false);
    const [goals, setGoals] = useState({});
    const [activeGoals, setActiveGoals] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [goalCategories, setGoalCategories] = useState([]);
    const [currentDayGoalActivities, setCurrentDayGoalActivities] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date())
    
    useEffect(() => {
        if(!props.isManageDefaultGoalView) {
            getActiveGoalList();
        }
        getGoalList();
        getGoalCategoryList();
        getGoalActivitiesForSelectDate();
    }, []);

    useEffect(() => {
        if(!props.isManageDefaultGoalView) {
            getActiveGoalList();
        }
        getGoalList();
    }, [category]);

    useEffect(() => {
        getGoalActivitiesForSelectDate()
    }, [selectedDate]);

    function getGoalActivitiesForSelectDate() {
        let date = dayjs(selectedDate)
        const startRange = date.startOf('day');
        const endRange = date.endOf('day');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}`;
        axios
            .get(url)
            .then((res) => {
                if (res.data.records) {
                    const list = {};
                    res.data.records.map((record) => {
                        list[record.goal_id] = record
                    })
                    setCurrentDayGoalActivities(list)
                }
            }, error => {
                alert(error);
            });
    }

    function getGoalCategoryList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_categories`;
        axios
            .get(url)
            .then((result) => {
                setGoalCategories(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function getActiveGoalList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals?join=goal_categories&filter=is_active,eq,1`;
        axios
            .get(url)
            .then((result) => {
                setActiveGoals(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function getGoalList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals?join=goal_categories&filter=type,eq,default`;
        if (category) {
            url += `&filter=category,eq,${category}`
        }
        axios
            .get(url)
            .then((result) => {
                const list = {}
                result.data.records.map((record) => {
                    if (record.category) {
                        list[record.category.name] = list[record.category.name] || [];
                        list[record.category.name].push(record);
                    }
                    
                });
                setGoals(list);
            }, error => {
                alert(error);
            });
    }

    function deleteGoal(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals/${id}`;
        axios
            .delete(url)
            .then((res) => {
                getGoalList()
            }, error => {
                alert(error);
            });
    }

    function markGoalCompleted(goalId) {
        console.log("goalIdgoalIdgoalId", goalId)
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity`;
        if (currentDayGoalActivities[goalId]) {
            url = url + `/${currentDayGoalActivities[goalId].id}`;
        }
        const data = {
            goal_id: goalId,
            added_on: dayjs(selectedDate).unix()
        }; 

        const promise = currentDayGoalActivities[goalId] ? axios.delete(url) : axios.post(url, data, {});
        promise
            .then((res) => {
                enableToastDisplay(true);
                getGoalActivitiesForSelectDate();
            }, error => {
                alert(error);
            });
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Changes Saved Success.</strong>
                    </Toast.Header>
                </Toast>
                <Button onClick={() => { navigate(props.isManageDefaultGoalView ? `/default-goal` : `/goal`) }}>Add Goal</Button>
                <Form.Group className="mb-3">
                    <Form.Label>Entry Date Time</Form.Label>
                    <ReactDatePicker
                        selected={selectedDate}
                        maxDate={new Date()}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="M/dd/yyyy"
                    />
                </Form.Group>
                {!props.isManageDefaultGoalView &&
                    <Stack gap={2}>
                        <h5 className='mt-2 mb-2' style={{ textDecoration: 'underline' }}>Active Goals</h5>
                        {activeGoals && activeGoals.map((item) =>
                            <Stack key={item.id} gap={2} direction="horizontal">
                                <strong className="me-auto">{item.name}</strong>
                                {/* <Button variant="danger" onClick={() => { deleteGoal(item.id) }}>Delete</Button> */}
                                <Button variant="primary" onClick={() => { navigate(`/goal/${item.id}`) }}>Edit</Button>
                                <Button variant={currentDayGoalActivities[item.id] ? "danger" : "success"} onClick={() => { markGoalCompleted(item.id) }}>
                                    {currentDayGoalActivities[item.id] ? "Mark as in-complete" : "Mark as complete"}
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                }
                <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Select aria-label="" onChange={(event) => { const category = event.target.value; setCategory(category); }} value={category}>
                        <option key="" value="">--Select--</option>
                        {goalCategories.map((category) => {
                            return (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
                <Stack gap={2}>
                    <h5 className='mt-2 mb-2' style={{ textDecoration: 'underline' }}>All Goals</h5>
                    {goals && Object.keys(goals).map((category) =>
                        <>
                            <h6 className='mt-2 mb-2' style={{ textDecoration: 'underline' }}>{category}</h6>
                            {goals[category].map((item) =>
                                <Stack key={item.id} gap={2} direction="horizontal">
                                    <strong className="me-auto">{item.name}</strong>
                                    {/* <Button variant="danger" onClick={() => { deleteGoal(item.id) }}>Delete</Button> */}
                                    <Button variant="primary" onClick={() => { navigate(`/${props.isManageDefaultGoalView ? 'default-goal' : 'goal'}/${item.id}`) }}>Edit</Button>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </Container>
        </div>
    );
}

export default ManageGoals;