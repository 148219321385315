import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, FloatingLabel, Image, Stack, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import React, { Component }  from 'react';
import { useNavigate } from "react-router-dom"
import * as dayjs from "dayjs";

function ManageHabits() {
    const navigate = useNavigate()
    const [habits, setHabits] = useState([]);
    const [habitSuccess, setHabitSuccess] = useState(true)
    const [failedHabitEntries, setFailedHabitEntries] = useState({});
    
    useEffect(() => {
        getHabits()
        getFailedHabitStatusEntries()
    }, []);

    function getHabits() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habits`;
        axios
            .get(url)
            .then((result) => {
                setHabits(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function getFailedHabitStatusEntries() {
        const utcOffset = Math.abs(dayjs().utcOffset()) * 60
        const startTime = dayjs().startOf('day').unix() + utcOffset;
        const endTime = dayjs().endOf('day').unix() + utcOffset
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity?filter=added_on,bt,${startTime},${endTime}`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const failedHabitEntries = {}
                records.map((record) => {
                    failedHabitEntries[record.habit_id] = record
                });
                setFailedHabitEntries(failedHabitEntries);
            }, error => {
                alert(error);
            });
    }

    function addHabitFailEntry(habitId) {
        const entry = failedHabitEntries[habitId]
        if (!entry.habitSuccess) {
            let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity${entry.id ? `/${entry.id}` : ''}`;
            const data = {
                tags: entry.tags,
                notes: entry.notes,
                added_on: dayjs(new Date()).unix(),
                habit_id: habitId
            };
            const promise = entry.id ? axios.put(url, data, {}) : axios.post(url, data, {})

            promise.then((res) => {
                
            }, error => {
                alert(error);
            });
        }
    }

    function deleteFailEntry(habitId) {
        const entry = failedHabitEntries[habitId];
        if (entry.id) {
            let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity/${entry.id}`;
            axios.delete(url).then((res) => {
                getFailedHabitStatusEntries()
            }, error => {
                alert(error);
            });
        }
    }

    function updateHabitFailEntry(habitId, field, value) {
        const updatedValue = {...failedHabitEntries};
        updatedValue[habitId] = updatedValue[habitId] || {}
        updatedValue[habitId][field] = value
        setFailedHabitEntries(updatedValue)
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Button onClick={() => { navigate(`/habit`) }}>Create Habit</Button>
                <Stack gap={2}>
                    {habits && habits.map((item) =>
                        <Stack key={item.id} gap={2} direction="horizontal">
                            <strong className="me-auto">{item.name}</strong>
                            {/* <Button variant="danger" onClick={() => { deleteGoal(item.id) }}>Delete</Button> */}
                            <Button variant="primary" onClick={() => { navigate(`/habit/${item.id}`) }}>Edit</Button>
                            <>
                                <Stack className='mb-2 mt-2' direction='horizontal' gap={3}>
                                    <Button variant="success" onClick={() => { updateHabitFailEntry(item.id, "habitSuccess", true); deleteFailEntry(item.id) }}>
                                        Success
                                    </Button>
                                    <Button variant="danger" onClick={() => { updateHabitFailEntry(item.id, "habitSuccess", false) }}>
                                        Fail
                                    </Button>
                                </Stack>
                                {failedHabitEntries[item.id] && !failedHabitEntries[item.id].habitSuccess &&
                                    <>
                                        <FloatingLabel label="Notes" className="mb-3">
                                            <Form.Control as="textarea" value={failedHabitEntries[item.id].notes} placeholder="Leave a note here" onChange={(evt) => { updateHabitFailEntry(item.id, "notes", evt.target.value);  }} />
                                        </FloatingLabel>
                                        <FloatingLabel label="Tags" className="mb-3">
                                            <Form.Control as="textarea" value={failedHabitEntries[item.id].tags} placeholder="Tags here" onChange={(evt) => { updateHabitFailEntry(item.id, "tags", evt.target.value); }} />
                                        </FloatingLabel>
                                        <Button onClick={() => { addHabitFailEntry(item.id) }}>
                                            Save
                                        </Button>
                                    </>
                                }
                            </>
                        </Stack>
                    )}
                </Stack>
            </Container>
        </div>
    );
}

export default ManageHabits;