import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Outlet } from "react-router-dom";
import React, { Component }  from 'react';

const Navigation = () => {
    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">The Journal</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/icon">Add Icon</Nav.Link>
                            <Nav.Link href="/manage-icons">Manage Icons</Nav.Link>
                            <Nav.Link href="/manage-tags">Manage Tags</Nav.Link>
                            <Nav.Link href="/option">Add Default Option</Nav.Link>
                            <Nav.Link href="/manage-options">Manage options</Nav.Link>
                            <Nav.Link href="/manage-user-options">Manage user options</Nav.Link>
                            <Nav.Link href="/entry">Add (+)</Nav.Link>
                            <Nav.Link href='/stats'>Stats</Nav.Link>
                            <Nav.Link href='/advanced-stats'>Advanced Stats</Nav.Link>
                            <Nav.Link href='/feed'>Feed</Nav.Link>
                            <Nav.Link href='/calender'>Calendar</Nav.Link>
                            <Nav.Link href='/add-default-goal'>Add Default Goal</Nav.Link>
                            <Nav.Link href='/add-default-habit'>Add Default Habit</Nav.Link>
                            <Nav.Link href='/more'>More</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
        </>
    )
};

export default Navigation;