import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Accordion, Button, Container, FloatingLabel, Form, Image, Stack, Toast } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import FileUploader from "../FileUploader";
import AddTag from "./addTag";
import AddUserOption from "./addUserOption";
import React, { Component } from 'react';
import * as dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import AddCustomSection from "./addCustomSection";
import AddCustomOption from "./addCustomOption";

function AddEntry() {
    let { id } = useParams();
    const [displayToast, enableToastDisplay] = useState(false)
    const [options, setOptions] = useState({});
    const [optionCategory, setOptionCategory] = useState("");
    const [entry, setEntry] = useState({
        rating: 0,
        sleepStartTime: "",
        sleepEndTime: "",
        sleepRating: 0,
        photos: [],
        notes: "",
        activityOptions: {},
        activityTagds: [],
        added_on: new Date(),
        name: "",
        content: "",
        template_id: "",
        journalEntryId: ""
    });
    const [tags, setTags] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({
        "feelings": [],
        "activities": [],
        "social-interaction": [],
        "meals": [],
        "medications": [],
        "drugs": [],
        "symptoms": []
    });
    const [addUserEntryModalShow, setAddUserEntryModalShow] = useState(false);
    const [addTagModalShow, setAddTagModalShow] = useState(false);
    const [optionId, setOptionId] = useState("");

    const [value, onChange] = useState(new Date());
    const [templates, setTemplates] = useState([]);
    const [showAddCustomSection, setShowAddCustomSection] = useState(false);
    const [customSections, setCustomSections] = useState([])
    const [showAddCustomOptionModal, setShowAddCustomOptionModal] = useState(false);
    const [selectedCustomSection, setSelectedCustomSection] = useState({});

    const [customFields, setCustomFields] = useState({});
    const [activeGoals, setActiveGoals] = useState([]);
    const [currentDayGoalActivities, setCurrentDayGoalActivities] = useState({});
    const [habits, setHabits] = useState([]);
    const [failedHabitEntries, setFailedHabitEntries] = useState({});
    const [habitCravingLevelEntry, setHabitCravingLevelEntry] = useState({});

    const categories = {
        "feelings": "Feelings",
        "activities": "Activities",
        "social-interaction": "Social Interaction",
        "meals": "Meals",
        "medications": "Medications / Herbs / Supplements",
        "drugs": "Drugs / Alcohol",
        "symptoms": "Symptoms"
    };

    const tagEligibleCategories = ["activities", "social-interaction", "meals", "medications", "drugs"];
    
    useEffect(() => {
        if (id) {
            getActivityInfo(id)
            getCravingLevelEntries(id)
        } else {
            renderOptions()
            getSleepActivity(new Date())
            getFailedHabitStatusEntries(entry.added_on)
        }
        renderCustomSections()
        getTemplateList()
        getActiveGoalList()
        getGoalActivitiesForToday()
        getHabits()
    }, []);

    function getTemplateList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/templates?order=type`;
        axios
            .get(url)
            .then((result) => {
                setTemplates(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function getActivityInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry/${id}?join=activity_options&join=activity_tags,tags&join=journal`;
        axios
            .get(url)
            .then((result) => {
                const activity = result.data;
                activity.added_on = dayjs(activity.added_on * 1000).toDate()
                activity.photos = activity.photos.length > 0 ? activity.photos.split(",") : []
                const activityOptions = {}
                activity.activity_options.map((option) => {
                    activityOptions[option.option_id] = option
                })
                activity.activityOptions = activityOptions
                if (activity.journal && activity.journal[0]) {
                    const journal = activity.journal[0];
                    activity.journalEntryId = journal.id;
                    activity.name = journal.name;
                    activity.content = journal.content;
                    activity.template_id = journal.template_id;
                }
                setEntry(activity)
                setTags(activity.activity_tags)
                getOptions(activity)
                getCustomFields(id)
                getSleepActivity(activity.added_on, activity)
                getFailedHabitStatusEntries(activity.added_on)
            }, error => {
                alert(error);
            });
    }

    function getCravingLevelEntries(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_craving_level_activity?filter=activity_id,eq,${id}`;
        axios.get(url)
            .then((result) => {
                const cravingLevelEnteries = {}
                result.data.records.map(record => {
                    cravingLevelEnteries[record.habit_id] = {
                        cravingLevel: record.craving_level,
                        tags: record.tags,
                        notes: record.notes,
                        habit_id: record.habit_id,
                        id: record.id
                    }
                });
                console.log("cravingLevelEnteries", cravingLevelEnteries)
                setHabitCravingLevelEntry(cravingLevelEnteries)
            });
    }

    function renderOptions() {
        getOptions();
    }

    function getOptions(activity = undefined) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options?join=icons&order=type&order=id`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const options = {};
                let updatedSelectedOptions = {
                    "feelings": [],
                    "activities": [],
                    "social-interaction": [],
                    "meals": [],
                    "medications": [],
                    "drugs": [],
                    "symptoms": [],
                    "custom": []
                };
                records.map((item) => {
                    const category = item.category
                    options[category] = options[category] || [];
                    item["isSelected"] = activity && activity.activityOptions.hasOwnProperty(item.id);
                    item["rating"] = activity && activity.activityOptions[item.id] ? activity.activityOptions[item.id].rating : null;
                    options[category].push(item);
                    if (item["isSelected"]) {
                        updatedSelectedOptions[category] = updatedSelectedOptions[category] || []
                        updatedSelectedOptions[category].push(item);
                    }
                    console.log("updatedValueupdatedValue", updatedSelectedOptions)

                });
                setSelectedOptions(updatedSelectedOptions);
                setOptions(options);
            }, error => {
                alert(error);
            });
    }

    function updateEntry(value, key) {
        let updatedValue = Object.assign({}, entry);
        updatedValue[key] = value;
        setEntry(updatedValue);
        console.log("updatedValue", updatedValue)
    }

    function updateTemplateValues(id) {
        let updatedValue = Object.assign({}, entry);
        let value = null;
        templates.map(template => {
            if (template.id == id) {
                value = template;
            }
        });

        updatedValue['name'] = value ? value.name : "";
        updatedValue['content'] = value ? value.questions : "";
        updatedValue['template_id'] = value ? value.id : "";
        setEntry(updatedValue);
        console.log("updatedValue", updatedValue)
    }

    function onOptionSelected(option, category) {
        if (option.isSelected) {
            deleteSelectedOption(option.id, category);
        } else {
            let updatedValue = Object.assign({}, selectedOptions);
            updatedValue[category].push(option);
            console.log("updatedValueupdatedValue", updatedValue)
            setSelectedOptions(updatedValue);
        }
        let updatedOptions = Object.assign({}, options);
        updatedOptions[category].map((item, index) => {
            if (option.id == item.id) {
                updatedOptions[category][index]["isSelected"] = !option.isSelected;
            }
        });
        setOptions(updatedOptions);
    }

    function setOptionRating(option, category, rating) {
        let updatedValue = Object.assign({}, selectedOptions);
        updatedValue[category].map((item, index) => {
            if (option.id == item.id) {
                updatedValue[category][index]["rating"] = rating;
            }
        });
        console.log("setOptionRatingsetOptionRatingsetOptionRating", updatedValue);
        setSelectedOptions(updatedValue);
    }

    function deleteSelectedOption(id, category) {
        let updatedValue = Object.assign({}, selectedOptions);
        updatedValue[category].map((item, index) => {
            if (id == item.id) {
                delete updatedValue[category][index];
                setSelectedOptions(updatedValue);
                console.log(selectedOptions);
                return;
            }
        });
    }

    function getSleepActivity(date, cachedEntry) {
        const startTime = dayjs(date).startOf('day');
        const endTime = dayjs(date).endOf('day');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_sleep?filter=sleep_start_time,bt,${startTime.unix()},${endTime.unix()}`;
        axios.get(url)
            .then((result) => {
                if (result.data.records && result.data.records.length) {
                    const record = result.data.records[0]
                    const activity = cachedEntry || { ...entry }
                    console.log("getSleepActivity", activity)
                    activity.sleepStartTime = record.sleep_start_time ? new Date(record.sleep_start_time * 1000) : "";
                    activity.sleepEndTime = record.sleep_end_time ? new Date(record.sleep_end_time * 1000) : "";
                    activity.sleepRating = record.sleep_rating
                    activity.sleepId = record.id
                    setEntry(activity)
                }
            })
    }

    function addOrUpdateSleep() {
        if (!entry.sleepRating || !entry.sleepStartTime || !entry.sleepEndTime) {
            return
        }
        const addedOnDate = dayjs(entry.added_on).format("YYYY-MM-DD")
        const startTime = dayjs(entry.sleepStartTime).format("HH:mm:ss")
        const endTime = dayjs(entry.sleepEndTime).format("HH:mm:ss")
        
        const data = {}
        let sleepStartTime = dayjs(`${addedOnDate} ${startTime}`).unix()
        let sleepEndTime = dayjs(`${addedOnDate} ${endTime}`).unix()
        sleepEndTime = sleepStartTime > sleepEndTime ? dayjs(`${addedOnDate} ${endTime}`).add(1, 'day').unix() : sleepEndTime;
        data.sleep_start_time = sleepStartTime;
        data.sleep_end_time = sleepEndTime;
        data.sleep_rating = entry.sleepRating;
        let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_sleep`;
        if (entry.sleepId) {
            url = url + `/${entry.sleepId}`
            axios.put(url, data, {})
        } else {
            axios.post(url, data, {})
        }
    }

    function getCustomFields(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_custom_fields?filter=activity_id,eq,${id}`;
        axios
            .get(url)
            .then((res) => {
                const items = {}
                res.data.records.map((record) => {
                    items[record.option_id] = record
                })
                setCustomFields(items)
            })
    }

    function createOrUpdateCustomSectionsEntries(activityId) {
        console.log(customFields)
        Object.keys(customFields).map((id) => {
            console.log(id)
            const entry = customFields[id]
            let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_custom_fields`;
            if (entry.id) {
                url = url + `/${entry.id}`;
            }
            const data = {
                rating: entry.rating || 0,
                activity_id: activityId,
                option_id: id
            };
            if (entry.id) {
                axios.put(url, data, {})
            } else {
                axios.post(url, data, {})
            }
        })
    }

    function createOrUpdateCravingLevelEnteries(activityId) {
        const promises = []
        console.log("habitCravingLevelEntry", habitCravingLevelEntry)
        Object.keys(habitCravingLevelEntry).map((habitId) => {
            const id = habitCravingLevelEntry[habitId].id;
            let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_craving_level_activity${id ? `/${id}` : ''}`;
            const data = {
                craving_level: habitCravingLevelEntry[habitId]['cravingLevel'],
                tags: habitCravingLevelEntry[habitId]['tags'],
                notes: habitCravingLevelEntry[habitId]['notes'],
                added_on: dayjs(entry.added_on).unix(),
                habit_id: habitId,
                activity_id: activityId
            };
            promises.push(id ? axios.put(url, data, {}) : axios.post(url, data, {}))
        })

        return promises
    }

    function createJournalActivity() {
        const utcOffset = dayjs().utcOffset() * 60
        console.log("utcOffsetutcOffset", utcOffset)
        addOrUpdateSleep()
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry`;
        if (entry.rating == 0) {
            return false;
        }

        const data = {
            rating: entry.rating,
            photos: entry.photos.join(','),
            notes: entry.notes,
            added_on: dayjs(entry.added_on).unix() + utcOffset,
            name: entry.name,
            content: entry.content,
            template_id: entry.template_id && entry.template_id.length ? entry.template_id : null
        };

        axios
            .post(url, data, {})
            .then((res) => {
                const activityId = res.data;
                createOrUpdateCustomSectionsEntries(activityId)
                const promises = [];
                if (entry.name.length > 0) {
                    promises.push(addOrUpdateJournalEntry(activityId))
                }
                promises.push(recordHabitFailActivities(activityId))
                const cravingLevelPromises = createOrUpdateCravingLevelEnteries(activityId)
                if (cravingLevelPromises.length > 0) {
                    promises.push(cravingLevelPromises)
                }
                Object.keys(selectedOptions).map((category) => {
                    selectedOptions[category].map((option) => {
                        if (option.id) {
                            promises.push(axios.post(`${process.env.REACT_APP_API_URL}index.php/records/activity_options`, { activity_id: activityId, option_id: option.id, rating: (option.rating || null) }, {}));
                        }
                    });
                });
                tags.map((tag) => {
                    promises.push(axios.post(`${process.env.REACT_APP_API_URL}index.php/records/activity_tags`, { activity_id: activityId, tag_id: tag.id }, {}));
                });
                Promise.all(promises).then((values) => {
                    enableToastDisplay(true)
                });
            }, error => {
                alert(error);
            });
    }

    function updateJournalActivity() {
        const promises = [];
        addOrUpdateSleep()
        createOrUpdateCustomSectionsEntries(id)
        promises.push(recordHabitFailActivities(id))

        const cravingLevelPromises = createOrUpdateCravingLevelEnteries(id)
        if (cravingLevelPromises.length > 0) {
            promises.push(cravingLevelPromises)
        }
        promises.push(addOrUpdateJournalEntry(id))
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry/${id}`;
        if (entry.rating == 0) {
            return false;
        }
        const data = {
            rating: entry.rating,
            photos: entry.photos.join(','),
            notes: entry.notes,
            added_on: dayjs(entry.added_on).unix()
        };

        axios.put(url, data, {})
        let deleteQuery = `${process.env.REACT_APP_API_URL}server/customApi.php?type=delete_activity_connections&id=${id}`;
        axios.get(deleteQuery).then(() => {
            Object.keys(selectedOptions).map((category) => {
                selectedOptions[category].map((option) => {
                    if (option.id) {
                        promises.push(axios.post(`${process.env.REACT_APP_API_URL}index.php/records/activity_options`, { activity_id: id, option_id: option.id, rating: (option.rating || null) }, {}));
                    }
                });
            });
            console.log("tagstagstagstagstagstagstags", tags)
            tags.map((tag) => {
                if (!tag.tag_id) {
                    promises.push(axios.post(`${process.env.REACT_APP_API_URL}index.php/records/activity_tags`, { activity_id: id, tag_id: tag.id }, {}));
                }
            });
            Promise.all(promises).then((values) => {
                enableToastDisplay(true)
            });
        });
    }

    function addOrUpdateJournalEntry(activityId) {
        console.log("entryentryentryentryentryentryentry", entry)
        const data = {
            name: entry.name,
            content: entry.content,
            template_id: entry.template_id ? entry.template_id : null,
            activity_id: activityId
        };
        const url = `${process.env.REACT_APP_API_URL}index.php/records/journal${entry.journalEntryId ? `/${entry.journalEntryId}` : ''}`;
        return entry.journalEntryId ? axios.put(url, data, {}) : axios.post(url, data, {});
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function onUserPhotoUploadComplete(files) {
        const photos = entry.photos || [];
        files.map((file) => {
            if (!file.error) { photos.push(file.path) }
        })
        updateEntry(photos, "photos")
    }

    function onTagSelected(tag) {
        let updatedValue = Object.assign([], tags);
        updatedValue.push(tag);
        setTags(updatedValue);
    }

    function deleteTag(tag) {
        let updatedValue = Object.assign([], tags);
        updatedValue.map((item, index) => {
            if (tag.id == item.id) {
                delete updatedValue[index];
                console.log("updatedValueupdatedValueupdatedValue", updatedValue)
                setTags(updatedValue);
                if (tag.tag_id) {
                    axios.delete(`${process.env.REACT_APP_API_URL}index.php/records/activity_tags/${tag.id}`)
                }
                return;
            }
        });
        console.log("updatedValueupdatedValueupdatedValue", updatedValue)
    }

    function deletePhotoAtIndex(index) {
        console.log(index)
        const photos = entry.photos
        photos.splice(index, 1)
        console.log(photos)
        updateEntry(photos, "photos")
    }

    function renderCustomSections() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/custom_sections`;
        axios
            .get(url)
            .then((res) => {
                setCustomSections(res.data.records)
            }, error => {
                alert(error);
            });
    }

    function updateCustomSectionEntry(id, value) {
        let updatedValue = Object.assign({}, customFields);
        updatedValue[id] = updatedValue[id] || {}
        updatedValue[id] = { ...updatedValue[id], ...value }
        console.log(updatedValue)
        setCustomFields(updatedValue)
    }

    function getActiveGoalList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals?oin=goal_categories&filter=is_active,eq,1`;
        axios
            .get(url)
            .then((result) => {
                setActiveGoals(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function getGoalActivitiesForToday() {
        let date = dayjs(new Date())
        const startRange = dayjs().startOf('day');
        const endRange = dayjs().endOf('day');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}`;
        axios
            .get(url)
            .then((res) => {
                if (res.data.records) {
                    const list = {};
                    res.data.records.map((record) => {
                        list[record.goal_id] = record
                    })
                    setCurrentDayGoalActivities(list)
                }
            }, error => {
                alert(error);
            });
    }

    function markGoalCompleted(goalId) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity`;
        if (currentDayGoalActivities[goalId]) {
            url = url + `/${currentDayGoalActivities[goalId].id}`;
        }
        const data = {
            goal_id: goalId,
            added_on: dayjs(entry.added_on).unix()
        };

        const promise = currentDayGoalActivities[goalId] ? axios.delete(url) : axios.post(url, data, {});
        promise
            .then((res) => {
                enableToastDisplay(true);
                getGoalActivitiesForToday();
            }, error => {
                alert(error);
            });
    }

    function getHabits() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habits`;
        axios
            .get(url)
            .then((result) => {
                setHabits(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function recordHabitFailActivities(activityId) {
        console.log("failedHabitEntriesfailedHabitEntries", failedHabitEntries)
        const promises = [];
        Object.keys(failedHabitEntries).map((habitId) => {
            promises.push(recordHabitFailEntry(habitId, activityId))
        })

        return promises
    }

    function recordHabitFailEntry(habitId, activityId) {
        const habit = failedHabitEntries[habitId]
        const id = habit.id;
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity${id ? `/${id}` : ''}`;
        const data = {
            tags: habit['tags'],
            notes: habit['notes'],
            added_on: dayjs(entry.added_on).unix(),
            habit_id: habitId,
            activity_id: activityId
        };
        return id ? axios.put(url, data, {}) : axios.post(url, data, {})
    }

    function deleteabitCravingLevelEntry(habitId) {
        const updatedValue = { ...habitCravingLevelEntry };
        delete updatedValue[habitId];
        console.log("setHabitCravingLevelEntry", updatedValue);
        setHabitCravingLevelEntry(updatedValue);
    }

    function deleteFailEntry(habitId) {
        const id = failedHabitEntries[habitId] ? failedHabitEntries[habitId].id : null;
        if (id) {
            let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity/${id}`;
            axios.delete(url).then((res) => {
                getFailedHabitStatusEntries()
            }, error => {
                alert(error);
            });
        }
    }

    function getFailedHabitStatusEntries(addedOn) {
        let date = dayjs(addedOn)
        const startRange = date.startOf('day');
        const endRange = date.endOf('day');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const list = {}
                records.map((record) => {
                    list[record.habit_id] = record;
                })
                setFailedHabitEntries(list)
            }, error => {
                alert(error);
            });
    }

    function updateHabitFailEntry(id, field, value) {
        const updatedValue = { ...failedHabitEntries };
        updatedValue[id] = updatedValue[id] || {};
        if (field) {
            updatedValue[id][field] = value;
        }
        setFailedHabitEntries(updatedValue);
    }

    function updateHabitCravingLevelEntry(id, field, value) {
        const updatedValue = { ...habitCravingLevelEntry };
        updatedValue[id] = updatedValue[id] || {};
        if (field) {
            updatedValue[id][field] = value;
        }
        setHabitCravingLevelEntry(updatedValue);
    }

    return (
        <Container>
            <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                <Toast.Header>
                    <strong className="me-auto">Action performed successfully.</strong>
                </Toast.Header>
            </Toast>
            <Stack gap={3}>
                <Form.Group className="mb-3">
                    <Form.Label>Entry Date Time</Form.Label>
                    <ReactDatePicker
                        selected={entry.added_on}
                        onChange={(date) => { updateEntry(date, "added_on"); getSleepActivity(date) }}
                        showTimeSelect
                        timeIntervals={15}
                        dateFormat="M/dd/yyyy h:mm aa"
                    />
                </Form.Group>
                <Stack direction="horizontal" gap={3}>
                    {[...Array(5)].map((x, i) =>
                        <i key={"main-rating-emoji" + i} className="bi bi-emoji-smile" onClick={() => { updateEntry(i + 1 != entry.rating ? i + 1 : 0, "rating") }} style={{ fontSize: '25px', color: entry.rating - 1 == i ? 'green' : "" }}></i>
                    )}
                </Stack>
                <Accordion defaultActiveKey="0">
                    {Object.keys(categories).map((category, key) => {
                        return (
                            <Accordion.Item key={key} eventKey={key}>
                                <Accordion.Header>{categories[category]}</Accordion.Header>
                                <Accordion.Body>
                                    <h5>Available</h5>
                                    <Stack direction="horizontal" gap={3}>
                                        {options[category] && options[category].map((option) => {
                                            return (
                                                <div style={{ display: 'grid' }}>
                                                    <Button key={option.name} variant={option.isSelected ? "primary" : "secondary"} onClick={() => { onOptionSelected(option, category) }} style={{ width: '100%' }}>
                                                        {option.icon_id && 
                                                            <Image thumbnail={true} width="25" src={process.env.REACT_APP_API_URL + option.icon_id.path} />
                                                        }
                                                        <div>{option.name}</div>
                                                    </Button>
                                                    {category == 'symptoms' && option.isSelected &&
                                                        <Stack direction="horizontal" gap={3} className="mx-auto">
                                                            {[...Array(5)].map((x, i) =>
                                                                <i key={"emoji" + i} className="bi bi-emoji-smile" onClick={() => { setOptionRating(option, category, i + 1 != option.rating ? i + 1 : 0) }} style={{ fontSize: '25px', color: option.rating && option.rating - 1 == i ? 'green' : "" }}></i>
                                                            )}
                                                        </Stack>
                                                    }
                                                </div>
                                            )
                                        })}
                                        <Button variant="primary" type="button" onClick={() => { setOptionCategory(category); setAddUserEntryModalShow(true) }}>
                                            Add New
                                        </Button>
                                    </Stack>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                    <Accordion.Item key="sleep" eventKey="sleep">
                        <Accordion.Header>Sleep</Accordion.Header>
                        <Accordion.Body>
                            <Stack direction="horizontal" gap={3}>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Start Time</Form.Label>
                                        <ReactDatePicker
                                            selected={entry.sleepStartTime}
                                            onChange={(date) => updateEntry(date, "sleepStartTime")}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>End Time</Form.Label>
                                        <ReactDatePicker
                                            selected={entry.sleepEndTime}
                                            onChange={(date) => updateEntry(date, "sleepEndTime")}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                        />
                                    </Form.Group>
                                    <Stack direction="horizontal" gap={3}>
                                        {/* {[...[5, 4, 3, 2, 1]].map((x, i) =>
                                            <Fragment key={"rating-" + x}>
                                                <input type="radio" name="rating" value={x} id={x} onClick={() => { updateEntry(x, "sleepRating") }} /><label htmlFor={x}>☆</label>
                                            </Fragment>
                                        )} */}
                                        {[...Array(5)].map((x, i) =>
                                            <i key={"sleep-emoji" + i} className="bi bi-emoji-smile" onClick={() => { updateEntry(i + 1 != entry.sleepRating ? i + 1 : 0, "sleepRating") }} style={{ fontSize: '25px', color: entry.sleepRating - 1 == i ? 'green' : "" }}></i>
                                        )}
                                    </Stack>
                                </Form>
                            </Stack>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item key="photos" eventKey="photos">
                        <Accordion.Header>Photos</Accordion.Header>
                        <Accordion.Body>
                            <FileUploader onCompletionHandler={onUserPhotoUploadComplete} />
                            <Stack direction="horizontal" gap={1} className="mt-3">
                                {entry.photos && entry.photos.map((item, index) =>
                                    <Stack gap={1} style={{ maxWidth: '120px' }}>
                                        <Image key={"userPhotos-" + index} thumbnail={true} width="100" style={{ maxHeight: "100px" }} src={process.env.REACT_APP_API_URL + item} />
                                        <Button variant="danger" onClick={() => { deletePhotoAtIndex(index) }} style={{ width: '100px' }}>Delete</Button>
                                    </Stack>
                                )}
                            </Stack>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item key="journal" eventKey="journal">
                        <Accordion.Header>Journal</Accordion.Header>
                        <Accordion.Body>
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Template</Form.Label>
                                    <Form.Select aria-label="" value={entry.template_id} onChange={(event) => { updateTemplateValues(event.target.value); }}>
                                        <option key="" value="">Blank Template</option>
                                        {templates.map((template) => {
                                            return (
                                                <option key={template} value={template.id}>{template.name}</option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" value={entry.name} placeholder="Jornal Name" onChange={(evt) => { updateEntry(evt.target.value, "name") }} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Template Content</Form.Label>
                                    <Form.Control as="textarea" value={entry.content} placeholder="Jornal Content" onChange={(evt) => { updateEntry(evt.target.value, "content") }} />
                                </Form.Group>
                            </>
                        </Accordion.Body>
                    </Accordion.Item>
                    {customSections && customSections.map((section) =>
                        <Accordion.Item key={section.name} eventKey={section.name}>
                            <Accordion.Header>{section.name}</Accordion.Header>
                            <Accordion.Body>
                                <>
                                    {section.field_type == 'rating' &&
                                        <Stack direction="horizontal" gap={3}>
                                            {[...Array(5)].map((x, i) =>
                                                <i key={"emoji" + i} className="bi bi-emoji-smile" onClick={() => { updateCustomSectionEntry(section.id, { rating: i + 1 != (customFields[section.id] && customFields[section.id].rating) ? i + 1 : 0 }) }} style={{ fontSize: '25px', color: customFields[section.id] && customFields[section.id].rating - 1 == i ? 'green' : "" }}></i>
                                            )}
                                        </Stack>
                                    }

                                    {section.field_type == 'options' &&
                                        <>
                                            <h5>Available</h5>
                                            <Stack direction="horizontal" gap={3}>
                                                {options["custom"] && options["custom"].map((option) => {
                                                    return (
                                                        <>
                                                            {option.custom_section_id == section.id &&
                                                                <Button key={option.name} variant={option.isSelected ? "primary" : "secondary"} onClick={() => { onOptionSelected(option, "custom") }}>
                                                                    {option.icon_id && 
                                                                        <Image thumbnail={true} width="25" src={process.env.REACT_APP_API_URL + option.icon_id.path} />
                                                                    }
                                                                    <div>{option.name}</div>
                                                                </Button>
                                                            }
                                                        </>
                                                    )
                                                })}
                                                <Button variant="primary" type="button" onClick={() => { console.log("setSelectedCustomSectionId", section.id); setSelectedCustomSection(section); setShowAddCustomOptionModal(true) }}>
                                                    Add New
                                                </Button>
                                            </Stack>
                                        </>
                                    }
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                    {activeGoals.length > 0 &&
                        <Accordion.Item key="goals" eventKey="goals">
                            <Accordion.Header>Goals</Accordion.Header>
                            <Accordion.Body>
                                <Stack gap={2}>
                                    {activeGoals && activeGoals.map((item) =>
                                        <Stack key={item.id} gap={2} direction="horizontal">
                                            <strong className="me-auto">{item.name}</strong>
                                            <Button variant={currentDayGoalActivities[item.id] ? "danger" : "success"} onClick={() => { markGoalCompleted(item.id) }}>
                                                {currentDayGoalActivities[item.id] ? "Mark as in-complete" : "Mark as complete"}
                                            </Button>
                                        </Stack>
                                    )}
                                </Stack>
                            </Accordion.Body>
                        </Accordion.Item>
                    }

                    {habits.length > 0 &&
                        <Accordion.Item key="habits" eventKey="habits">
                            <Accordion.Header>Habits</Accordion.Header>
                            <Accordion.Body>
                                <Stack gap={2}>
                                    {habits && habits.map((item) =>
                                        <Stack key={item.id} gap={2} direction="horizontal">
                                            <strong className="me-auto" style={{ width: '80%' }}>{item.name}</strong>
                                            <Stack>
                                                <Stack direction="horizontal" gap={3}>
                                                    {[...Array(5)].map((x, i) =>
                                                        <i key={"emoji" + i} className="bi bi-emoji-smile" onClick={() => { updateHabitCravingLevelEntry(item.id, 'cravingLevel', i + 1) }} style={{ fontSize: '25px', color: habitCravingLevelEntry[item.id] && habitCravingLevelEntry[item.id]['cravingLevel'] - 1 == i ? 'green' : "" }}></i>
                                                    )}
                                                </Stack>
                                                {habitCravingLevelEntry[item.id] &&
                                                    <>
                                                        {/* <FloatingLabel label="Notes" className="mb-3">
                                                            <Form.Control as="textarea" value={habitCravingLevelEntry[item.id]['notes']} placeholder="Leave a note here" onChange={(evt) => { updateHabitCravingLevelEntry(item.id, 'notes', evt.target.value) }} />
                                                        </FloatingLabel>
                                                        <FloatingLabel label="Tags" className="mb-3">
                                                            <Form.Control as="textarea" value={habitCravingLevelEntry[item.id]['tags']} placeholder="Tags here" onChange={(evt) => { updateHabitCravingLevelEntry(item.id, 'tags', evt.target.value) }} />
                                                        </FloatingLabel> */}
                                                        <Button onClick={() => { deleteabitCravingLevelEntry(item.id) }}>
                                                            Clear
                                                        </Button>
                                                    </>
                                                }
                                                <Stack className='mb-2 mt-2' direction='horizontal' gap={3}>
                                                    <Button variant="success" onClick={() => { deleteFailEntry(item.id) }}>
                                                        Success
                                                    </Button>
                                                    <Button variant="danger" onClick={() => { updateHabitFailEntry(item.id) }}>
                                                        Fail
                                                    </Button>
                                                </Stack>
                                                {failedHabitEntries[item.id] &&
                                                    <>
                                                        <FloatingLabel label="Notes" className="mb-3">
                                                            <Form.Control as="textarea" value={failedHabitEntries[item.id]['notes']} placeholder="Leave a note here" onChange={(evt) => { updateHabitFailEntry(item.id, 'notes', evt.target.value) }} />
                                                        </FloatingLabel>
                                                        <FloatingLabel label="Tags" className="mb-3">
                                                            <Form.Control as="textarea" value={failedHabitEntries[item.id]['tags']} placeholder="Tags here" onChange={(evt) => { updateHabitFailEntry(item.id, 'tags', evt.target.value) }} />
                                                        </FloatingLabel>
                                                    </>
                                                }
                                            </Stack>
                                        </Stack>
                                    )}

                                </Stack>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                </Accordion>
                <Stack gap={2}>
                    <Stack direction="horizontal" gap={3}>
                        {tags && tags.map((tag) => {
                            return (
                                <Button variant="warning" key={tag.tag_id ? tag.tag_id.name : tag.name} onClick={(e) => { e.stopPropagation(); deleteTag(tag); return true; }}>
                                    {tag.tag_id ? tag.tag_id.name : tag.name}
                                </Button>
                            )
                        })}
                    </Stack>
                    <Button variant="info" type="button" onClick={(e) => { e.stopPropagation(); setAddTagModalShow(true); return true; }}>
                        Add Tag
                    </Button>
                </Stack>
                <Stack style={{ border: '1px solid #dee2e6', padding: '5px' }}>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" value={entry.notes} placeholder="Leave a note here" onChange={(evt) => { updateEntry(evt.target.value, "notes") }} />
                </Stack>
                <Button variant="primary" onClick={() => { setShowAddCustomSection(true) }} style={{ width: '200px' }}>Add Custom Section</Button>
                <AddUserOption
                    optionCategory={optionCategory}
                    show={addUserEntryModalShow}
                    completionHandler={() => { renderOptions(); setAddUserEntryModalShow(false); }}
                    onHide={() => setAddUserEntryModalShow(false)}
                />
                <AddCustomOption
                    customSection={selectedCustomSection}
                    show={showAddCustomOptionModal}
                    completionHandler={() => { renderOptions(); setShowAddCustomOptionModal(false); }}
                    onHide={() => setShowAddCustomOptionModal(false)}
                />
                <AddTag
                    show={addTagModalShow}
                    completionHandler={() => { renderOptions(); }}
                    onHide={() => setAddTagModalShow(false)}
                    onTagSelected={onTagSelected}
                />
                <AddCustomSection
                    show={showAddCustomSection}
                    completionHandler={() => { renderCustomSections(); setShowAddCustomSection(false); }}
                    onHide={() => setShowAddCustomSection(false)}
                />
                <Button variant="success" onClick={() => {
                    if (id) {
                        updateJournalActivity()
                    } else {
                        createJournalActivity()
                    }
                }}>Save</Button>
            </Stack>
        </Container>
    )
}

export default AddEntry;