import axios from "axios";
import React, { forwardRef, Fragment, useEffect, useState } from "react"
import { Button, Col, Form, Image, Row, Stack, Table } from "react-bootstrap";

import * as dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EntryListing from "./entryListing";
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


function Feed(props) {
    let [entries, setEntries] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [enableSearchMode, setEnableSearchMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [range, setRange] = useState("");
    const [customRangeStartTime, setCustomRangeStartTime] = useState("");
    const [customRangeEndime, setCustomRangeEndTime] = useState("");
    const [goalActivities, setGoalActivities] = useState({});
    const [habits, setHabits] = useState([]);
    const [failedHabitEntries, setFailedHabitEntries] = useState({});
    const [goals, setGoals] = useState([]);
    const [sleepActivities, setSleepActivities] = useState({});

    let [selectedCategories, setSelectedCategories] = useState([
        "feelings",
        "activities",
        "social-interaction",
        "meals",
        "medications",
        "drugs",
        "symptoms",
        "tags",
        "notes",
        "journal"
    ]);

    const categories = {
        "feelings": "Feelings",
        "activities": "Activities",
        "social-interaction": "Social Interaction",
        "meals": "Meals",
        "medications": "Medications / Herbs / Supplements",
        "drugs": "Drugs / Alcohol",
        "symptoms": "Symptoms",
        "tags": "Tags",
        "notes": "Notes",
        "journal": "Journal"
    };

    const DateSwitcher = forwardRef(({ value, onClick }, ref) => (
        <Button style={{ marginLeft: '50px' }} onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    useEffect(() => {
        getEntries();
        getGoals();
    }, []);

    useEffect(() => {
        getEntries()
        getHabits()
        getFailedHabitStatusEntries()
        getGoalActivities()
        getSleepActivities()
    }, [startDate])

    useEffect(() => {
        if (searchTerm && searchTerm.length > 0) {
            searchEntries();
        }
    }, [range]);

    function getEntries() {
        if (enableSearchMode && searchTerm.length == 0) {
            return
        }
        const firstDayOfMonth = dayjs(startDate).startOf('month');
        const lastDayOfMonth = dayjs(startDate).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}&join=activity_options,options&join=activity_tags,tags&join=habit_craving_level_activity&join=activity_custom_fields&order=added_on,desc`;
        axios.get(url).then((result) => {
            if (result.data.records) {
                setEntries(result.data.records)
            }
        });
    }

    function searchEntries() {
        if (searchTerm && searchTerm.length == 0) {
            return
        }
        let date = dayjs(new Date())
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=search_enteries&searchTerm=${searchTerm}&category=${selectedCategories.join("','")}`
        if (range == "last-7-days") {
            const startRange = dayjs(startDate).subtract(7, 'day');
            url = url + `&start=${startRange.unix()}&end=${date.unix()}`
        } else if (range == "last-30-days") {
            const startRange = dayjs(startDate).subtract(30, 'day');
            url = url + `&start=${startRange.unix()}&end=${date.unix()}`
        } else if (range == "last-12-months") {
            const startRange = dayjs(startDate).subtract(12, 'month');
            url = url + `&start=${startRange.unix()}&end=${date.unix()}`
        } else if (range == "custom") {
            const startRange = dayjs(customRangeStartTime).startOf('day');
            const endRange = dayjs(customRangeEndime).endOf('day');
            url = url + `&start=${startRange.unix()}&end=${endRange.unix()}`
        }
        axios.get(url)
            .then((res) => {
                const ids = [];
                if (res.data.records) {
                    res.data.records.map(record => {
                        ids.push(record.id)
                    })
                }
                if (ids.length) {
                    let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?join=activity_options,options&join=activity_tags,tags&filter=id,in,${ids.join(",")}&join=habit_craving_level_activity&join=activity_custom_fields&order=added_on,desc`;
                    axios.get(url).then((result) => {
                        if (result.data.records) {
                            setEntries(result.data.records)
                        }
                    });
                }
            }, error => {
                alert(error);
            });
    }


    function getGoals() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals`;
        axios
            .get(url)
            .then((res) => {
                if (res.data.records) {
                    setGoals(res.data.records)
                }
            }, error => {
                alert(error);
            });
    }

    function onDateChange(date) {
        setStartDate(date)
    }

    function deleteEntry(id) {
        axios.post(`${process.env.REACT_APP_API_URL}server/customApi.php?type=delete_activity&id=${id}`).then(() => {
            getEntries()
        });
    }

    function onChangeHandler(category, event) {
        if (event.target.checked) {
            selectedCategories.push(category)
        } else {
            selectedCategories.splice(selectedCategories.indexOf(category), 1)
        }
        setSelectedCategories(selectedCategories)
        searchEntries()
    }


    function getHabits() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habits`;
        axios
            .get(url)
            .then((result) => {
                setHabits(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function addHabitFailEntry(habitId, date) {
        const id = failedHabitEntries[date][habitId].id;
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity${id ? `/${id}` : ''}`;
        const data = {
            tags: failedHabitEntries[date][habitId]['tags'],
            notes: failedHabitEntries[date][habitId]['notes'],
            added_on: dayjs(date).unix(),
            habit_id: habitId
        };
        const promise = id ? axios.put(url, data, {}) : axios.post(url, data, {})

        promise.then((res) => {
            updateHabitFailEntry(id, 'id', res.data, date)
        }, error => {
            alert(error);
        });
    }

    function getSleepActivities() {
        let date = dayjs(startDate)
        const startRange = date.startOf('month');
        const endRange = date.endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_sleep?filter=sleep_start_time,bt,${startRange.unix()},${endRange.unix()}`;
        axios
            .get(url)
            .then((result) => {
                const utcOffset = dayjs().utcOffset() * 60
                const records = result.data.records;
                if (records) {
                    const list = {}
                    records.map((record) => {
                        const addedOn = dayjs((record.sleep_start_time) * 1000).utc().format("YYYY-MM-DD");
                        list[addedOn] = record;
                    })
                    console.log("getSleepActivities", list)
                    setSleepActivities(list)
                }
            }, error => {
                alert(error);
            });
    }

    function getGoalActivities() {
        let date = dayjs(startDate)
        const startRange = date.startOf('month');
        const endRange = date.endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const utcOffset = dayjs().utcOffset() * 60
                if (records) {
                    const list = {}
                    records.map((record) => {
                        const addedOn = dayjs((record.added_on) * 1000).utc().format("YYYY-MM-DD");
                        list[addedOn] = list[addedOn] || {};
                        list[addedOn][record.goal_id] = record;
                    })
                    setGoalActivities(list)
                }
            }, error => {
                alert(error);
            });
    }

    function markGoalCompleted(goalId, date) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity`;
        if (goalActivities[goalId]) {
            url = url + `/${goalActivities[goalId].id}`;
        }
        const data = {
            goal_id: goalId,
            added_on: dayjs(date).unix()
        }; 

        const promise = goalActivities[goalId] ? axios.delete(url) : axios.post(url, data, {});
        promise
            .then((res) => {
                getGoalActivities()
            }, error => {
                alert(error);
            });
    }

    function deleteFailEntry(habitId, date) {
        const id = failedHabitEntries[date] && failedHabitEntries[date][habitId] ? failedHabitEntries[date][habitId].id : null;
        if (id) {
            let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity/${id}`;
            axios.delete(url).then((res) => {
                getFailedHabitStatusEntries()
            }, error => {
                alert(error);
            });
        }
    }

    function getFailedHabitStatusEntries() {
        let date = dayjs(startDate)
        const startRange = date.startOf('month');
        const endRange = date.endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const list = {}
                const utcOffset = dayjs().utcOffset() * 60
                records.map((record) => {
                    const addedOn = dayjs((record.added_on) * 1000).utc().format("YYYY-MM-DD");
                    list[addedOn] = list[addedOn] || {};
                    list[addedOn][record.habit_id] = record;
                })
                console.log("getFailedHabitStatusEntriesgetFailedHabitStatusEntries", list);
                setFailedHabitEntries(list)
            }, error => {
                alert(error);
            });
    }

    function updateHabitFailEntry(id, field, value, date) {
        const updatedValue = { ...failedHabitEntries };
        updatedValue[date] = updatedValue[date] || {};
        updatedValue[date][id] = updatedValue[date][id] || {};
        if (field) {
            updatedValue[date][id][field] = value;
        }
        console.log(updatedValue, date);
        setFailedHabitEntries(updatedValue);
    }

    return (
        <>
            {enableSearchMode &&
                <div className="mx-5">
                    <Form>
                        <Stack gap={3} className="col-md-5 mt-2" >
                            <Form.Group className="mb-3">
                                <Form.Label>Term</Form.Label>
                                <Form.Control type="text" value={searchTerm} placeholder="Enter Term" onChange={(event) => { setSearchTerm(event.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Range</Form.Label>
                                <Form.Select aria-label="" onChange={(event) => { setEntries([]); setRange(event.target.value); }} value={range}>
                                    <option key="" value="all-time">All Time</option>
                                    <option key="" value="last-7-days">Last 7 days</option>
                                    <option key="" value="last-30-days">Last 30 days</option>
                                    <option key="" value="last-12-months">Last 12 months</option>
                                    <option key="" value="custom">Custom</option>
                                </Form.Select>
                            </Form.Group>
                            {range == "custom" &&
                                <Form.Group className="mb-3">
                                    <Form.Label>Range</Form.Label>
                                    <Stack direction="horizontal">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Start</Form.Label>
                                            <ReactDatePicker
                                                selected={customRangeStartTime}
                                                onChange={(date) => setCustomRangeStartTime(date)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>End</Form.Label>
                                            <ReactDatePicker
                                                selected={customRangeEndime}
                                                onChange={(date) => setCustomRangeEndTime(date)}
                                            />
                                        </Form.Group>
                                    </Stack>
                                </Form.Group>
                            }
                            <Stack direction="horizontal" gap={3}>
                                {Object.keys(categories).map((key) => {
                                    return (
                                        <Form.Group key={key} className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" defaultChecked={selectedCategories.indexOf(key) > -1} name={key} onChange={(evt) => onChangeHandler(key, evt)} value={key} label={categories[key]} />
                                        </Form.Group>
                                    );
                                })}
                            </Stack>
                        </Stack>
                        <Button className="mx-5" variant="primary" onClick={() => { setEntries([]); searchEntries(); }}>Search</Button>
                    </Form>
                </div>
            }
            {!enableSearchMode &&
                <>
                    <Button className="mx-5" variant="primary" onClick={() => { setEntries([]); setEnableSearchMode(true) }}>Search</Button>
                    <Stack direction="horizontal" gap={5} className="p-5">
                        <Button variant="secondary" onClick={() => { const date = dayjs(startDate).subtract(1, 'month'); onDateChange(date); }}>Prev</Button>
                        {dayjs(startDate).format('MMM')}
                        <Button disabled={dayjs(startDate).month() == dayjs().month()} variant="secondary" onClick={() => { const date = dayjs(startDate).add(1, 'month'); onDateChange(date); }}>Next</Button>
                    </Stack>
                </>
            }
            <EntryListing
                minimizedView = {searchTerm && searchTerm.length > 0}
                displayManageEntryOption={true}
                entries={entries}
                goals={goals}
                goalActivities={goalActivities}
                markGoalCompleted={markGoalCompleted}
                habits={habits}
                failedHabitEntries={failedHabitEntries}
                deleteEntry={deleteEntry}
                addHabitFailEntry={addHabitFailEntry}
                deleteFailEntry={deleteFailEntry}
                updateHabitFailEntry={updateHabitFailEntry}
                sleepActivities={sleepActivities}
            />
        </>
    )
}

export default Feed;