import axios from "axios";
import React, { forwardRef, Fragment, useEffect, useState } from "react"
import { Button, Col, FloatingLabel, Form, Image, Modal, Row, Stack, Table } from "react-bootstrap";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import * as dayjs from "dayjs";
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

function EntryListing(props) {
    const navigate = useNavigate()
    const [viewEntry, setViewEntry] = useState(false);
    const [name, setName] = useState("");
    const [content, setContent] = useState("");

    const [entries, setEntries] = useState({});

    const [dateFormatType, setDateFormatType] = useState({});

    useEffect(() => {
        const groupedEntries = {};
        if (props.entries) {
            const utcOffset = dayjs().utcOffset() * 60
            props.entries.map((entry) => {
                const addedOn = dayjs((entry.added_on) * 1000).utc().local().format("YYYY-MM-DD");
                groupedEntries[addedOn] = groupedEntries[addedOn] || [];
                groupedEntries[addedOn].push(entry)
            })
            setEntries(groupedEntries)
        }
    }, [props.entries]);

    return (
        <>
            {Object.keys(entries).length > 0 &&
                <>
                    <Table striped bordered hover style={{ maxWidth: '400px' }} className="m-5">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Rating</th>
                                <th>Notes</th>
                                <th>Created On</th>
                                {/* <th>Sleep Start Time</th>
                                <th>Sleep End Time</th>
                                <th>Sleep Rating</th> */}
                                <th>Photos</th>
                                <th>Options</th>
                                <th>Tags</th>
                                <th>Craving Levels</th>
                                <th>Custom Rating Option</th>
                                {props.habits &&
                                    <th></th>
                                }
                                <th></th>
                                {(props.displayManageEntryOption || false) &&
                                    <th></th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(entries).map((date) =>
                                <>
                                    <tr>
                                        <td colSpan="12">
                                            <h2>{date}</h2>
                                            {props.sleepActivities && props.sleepActivities[date] &&
                                                <Stack gap={1}>
                                                    <h5>Sleep Rating - {props.sleepActivities[date]['sleep_rating']}</h5>
                                                    <h5>Sleep Duration - {(props.sleepActivities[date]['sleep_end_time'] - props.sleepActivities[date]['sleep_start_time']) / 3600} hrs</h5>
                                                </Stack>
                                            }
                                            <Stack gap={1}>
                                                {props.minimizedView === false && props.goals && props.goals.map((item) =>
                                                    <Stack direction="horizontal" gap={1}>
                                                        <Button variant="secondary" onClick={() => { navigate(`/goal/${item.id}/${date}`) }}>{item.name}</Button>
                                                        <Button variant={props.goalActivities[date] && props.goalActivities[date][item.id] ? "danger" : "success"} onClick={() => { props.markGoalCompleted(item.id, date) }}>
                                                            {props.goalActivities[date] && props.goalActivities[date][item.id] ? "Mark as in-complete" : "Mark as complete"}
                                                        </Button>
                                                    </Stack>
                                                )}
                                            </Stack>
                                            <Stack>
                                                {props.minimizedView === false && props.habits && props.habits.map((item) =>
                                                    <Stack key={item.id} gap={2} direction="horizontal">
                                                        <Button variant="secondary" onClick={() => { navigate(`/habit/${item.id}/${date}`) }} style={{ width: '200px', marginRight: '100px' }}>{item.name}</Button>
                                                        <Stack>
                                                            <Stack className='mb-2 mt-2' direction='horizontal' gap={3}>
                                                                <Button variant="success" onClick={() => { props.deleteFailEntry(item.id, date) }}>
                                                                    Success
                                                                </Button>
                                                                <Button variant="danger" onClick={() => { props.updateHabitFailEntry(item.id, undefined, null, date) }}>
                                                                    Fail
                                                                </Button>
                                                            </Stack>
                                                            {props.failedHabitEntries[date] && props.failedHabitEntries[date][item.id] &&
                                                                <>
                                                                    <FloatingLabel label="Notes" className="mb-3">
                                                                        <Form.Control as="textarea" value={props.failedHabitEntries[date][item.id]['notes']} placeholder="Leave a note here" onChange={(evt) => { props.updateHabitFailEntry(item.id, 'notes', evt.target.value, date) }} />
                                                                    </FloatingLabel>
                                                                    <FloatingLabel label="Tags" className="mb-3">
                                                                        <Form.Control as="textarea" value={props.failedHabitEntries[date][item.id]['tags']} placeholder="Tags here" onChange={(evt) => { props.updateHabitFailEntry(item.id, 'tags', evt.target.value, date) }} />
                                                                    </FloatingLabel>
                                                                    <Button onClick={() => { props.addHabitFailEntry(item.id, date) }}>
                                                                        Save
                                                                    </Button>
                                                                </>
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                )}
                                            </Stack>
                                        </td>
                                    </tr>
                                    {entries[date].map((entry) =>
                                        <tr key={entry.id}>
                                            <td>{entry.name}</td>
                                            <td>{entry.rating}</td>
                                            <td>{entry.notes}</td>
                                            <td>{dayjs(entry.added_on * 1000).utc().format()}</td>
                                            {/* <td>{entry.sleep_start_time ? dayjs(entry.sleep_start_time * 1000).format() : ""}</td>
                                    <td>{entry.sleep_start_time ? dayjs(entry.sleep_end_time * 1000).format() : ""}</td>
                                    <td>{entry.sleep_rating}</td> */}
                                            <td>
                                                <Row>
                                                    {entry.photos && entry.photos.split(",").map((photo, index) =>
                                                        <Col key={index}><Image thumbnail={true} width="50" height={50} style={{ maxHeight: '50px', maxWidth: '50px' }} src={process.env.REACT_APP_API_URL + photo} /></Col>
                                                    )}
                                                </Row>
                                            </td>
                                            <td>
                                                <Stack gap={1}>
                                                    {entry.activity_options && entry.activity_options.map((option, index) =>
                                                        <div key={option.option_id.id} className="bg-light border p-3">{option.option_id.name} <span style={{ fontWeight: 'bold' }}>{option.rating ? ` with rating - ${option.rating}` : ""}</span></div>
                                                    )}
                                                </Stack>
                                            </td>
                                            <td>
                                                <Stack gap={1}>
                                                    {entry.activity_tags && entry.activity_tags.map((tag, index) =>
                                                        <div key={tag.tag_id.id} className="bg-light border p-3">{tag.tag_id.name}</div>
                                                    )}
                                                </Stack>
                                            </td>
                                            <td>
                                                <Stack gap={1}>
                                                    {entry.habit_craving_level_activity && entry.habit_craving_level_activity.map((activity, index) =>
                                                        <Stack direction="horizontal" gap={1}>
                                                            <div key={"cl-" + activity.id} className="bg-light border p-3">{activity.craving_level}</div>
                                                            {/* <div key={"notes-" + activity.id} className="bg-light border p-3">{activity.notes}</div>
                                                            <div key={"tags-" + activity.id} className="bg-light border p-3">{activity.tags}</div> */}
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            </td>
                                            <td>
                                                <Stack gap={1}>
                                                    {entry.activity_custom_fields && entry.activity_custom_fields.map((customOption, index) =>
                                                        <Stack direction="horizontal" gap={1}>
                                                            <div key={"cl-" + customOption.id} className="bg-light border p-3">{customOption.rating}</div>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            </td>
                                            {(props.displayManageEntryOption || false) &&
                                                <>
                                                    <td>
                                                        <Button onClick={() => { setName(entry.name); setContent(entry.content); setViewEntry(true) }}>View</Button>
                                                    </td>
                                                    <td>
                                                        <Button onClick={() => { navigate(`/entry/${entry.id}`) }}>Edit</Button>
                                                    </td>
                                                    <td>
                                                        <Button onClick={() => { props.deleteEntry(entry.id) }}>Delete</Button>
                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </Table>
                </>
            }
            <Modal show={viewEntry} onHide={() => { setViewEntry(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body><span style={{ whiteSpace: 'pre-line' }}>{content}</span></Modal.Body>
            </Modal>
        </>
    )
}

export default EntryListing;