import axios from "axios";
import React, { forwardRef, Fragment, useEffect, useState } from "react"
import { Button, Form, Stack, Table } from "react-bootstrap";
import ChartView from "./chartView";
import * as dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Stats(props) {
    let [stats, setStats] = useState({});
    let [tableStats, setTableStats] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [options, setOptions] = useState({});
    const [customRatingOptions, setCustomRatingOptions] = useState({});

    let [selectedCategory, setSelectedCategory] = useState("");
    let [selectedOption, setSelectedOption] = useState("");
    const [customCategoryKeys, setCustomCategoryKeys] = useState([]);
    const [customOptionsSectionKeys, setCustomOptionsSectionKeys] = useState([]);

    const DateSwitcher = forwardRef(({ value, onClick }, ref) => (
        <Button style={{ marginLeft: '50px' }} onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    const categoryKeys = [
        ["rating", "rating-likelihood", "tags"],
        ["feelings", "activities", "social-interaction"],
        ["meals", "medications", "drugs"],
        ["symptoms", "often-together", "often-together-with-rating", "often-together-with-custom-rating"]
    ];

    const graphKeys = [
        ["rating-chart", "sleep-rating-chart", "sleep-hours"],
        ["avg-rating-chart-by-day", "avg-rating-chart-by-sleep-hours"]
    ];
    const categories = {
        "feelings": "Feelings",
        "activities": "Activities",
        "social-interaction": "Social Interaction",
        "meals": "Meals",
        "medications": "Medications / Herbs / Supplements",
        "drugs": "Drugs / Alcohol",
        "symptoms": "Symptoms"
    };

    const oftenTogetherCategoryMapping = {
        "rating": ["activities", "social-interaction", "meals", "medications", "drugs", "custom"],
        "feelings": ["feelings", "activities", "social-interaction", "meals", "medications", "drugs", "custom"],
        "activities": ["activities", "rating", "feelings", "symptoms", "custom"],
        "social-interaction": ["social-interaction", "rating", "feelings", "symptoms", "custom"],
        "meals": ["meals", "rating", "feelings", "symptoms", "custom"],
        "medications": ["medications", "rating", "feelings", "symptoms", "custom"],
        "drugs": ["drugs", "rating", "feelings", "symptoms", "custom"],
        "symptoms": ["symptoms", "rating", "feelings", "symptoms", "custom"],
        "custom": ["custom", "rating", "feelings", "symptoms"],
        "customRating": ["feelings", "activities", "social-interaction", "meals", "medications", "drugs", "custom"]
    }

    const dateGraphs = ['sleep-rating-chart', 'rating-chart', 'sleep-hours']

    useEffect(() => {
        loadStats(startDate)
        getOptions()
        getCustomRatingOptions()
    }, []);

    useEffect(() => {
        fetchOftenTogetherStats()
    }, [selectedCategory, selectedOption]);

    function loadStats(date, loadOftenTogetherStats = false) {
        stats = {
            ...stats
        };
        delete stats['rating-chart']
        delete stats['sleep-rating-chart']
        delete stats['sleep-hours']
        delete stats['avg-rating-chart-by-day']
        delete stats['avg-rating-chart-by-sleep-hours']
        setStats(stats);
        const promises = [];
        promises.push(getAverageRating(date))
        promises.push(getRatingStats(date))
        promises.push(getTagStats(date))
        promises.push(getSleepStats(date))
        promises.push(getPreviousMonthSleepStats(date))
        promises.push(getCustomOptionsStats(date))
        promises.push(getCustomSectionRatingStats(date))
        Object.keys(categories).map((category) => {
            promises.push(getCategoryStats(category, date));
        });
        Promise.all(promises).then((result) => {
            processActivityInfo(result[0], date);
            processRatingStats(result[1], date);
            processTagStats(result[2], date);
            processSleepStats(result[3], date);
            processPreviousSleepStats(result[4], date);
            processCustomOptionsStats(result[5], date);
            processCustomRatingStats(result[6], date);
            Object.keys(categories).map((category, index) => {
                processCategoryStats(result[7 + index], category, date);
            });
            if (loadOftenTogetherStats) {
                fetchOftenTogetherStats(date)
            }
            fetchMoodStatsBySleepHours(date)
        });
    }

    function getAverageRating(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}`;
        return axios.get(url);
    }

    function processActivityInfo(result) {
        const firstDayOfMonth = dayjs(startDate).startOf('month');
        const lastDayOfMonth = dayjs(startDate).endOf('month');
        const records = result.data.records;
        const averageRatings = [];
        const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
        const data = {};
        const ratingByDays = {};
        const ratingsByWeekDays = {};
        labels.map((key) => {
            data[key] = 0;
            ratingByDays[key] = []
        });

        const ratingByDaysLabels = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];
        const ratingByWeekday = {};
        ratingByDaysLabels.map((key) => {
            ratingByWeekday[key] = 0;
            ratingsByWeekDays[key] = []
        });
        const utcOffset = dayjs().utcOffset() * 60
        records.map((item) => {
            const addedOnDay = dayjs((item.added_on+utcOffset) * 1000);
            ratingByDays[addedOnDay.date()].push(item.rating);
            const weekday = ratingByDaysLabels[addedOnDay.day()]
            ratingsByWeekDays[weekday].push(item.rating)
        });

        Object.keys(ratingByDays).map((key) => {
            if (ratingByDays[key].length > 0) {
                data[key] = ratingByDays[key].reduce((a, b) => a + b, 0) / ratingByDays[key].length
            }
        })
        Object.keys(ratingsByWeekDays).map((key) => {
            if (ratingsByWeekDays[key].length > 0) {
                ratingByWeekday[key] = ratingsByWeekDays[key].reduce((a, b) => a + b, 0) / ratingsByWeekDays[key].length
            }
        })
        const updatedValue = {}
        updatedValue['rating-chart'] = {
            'title': "Average Rating Chart",
            'data': {
                datasets: [
                    {
                        label: "",
                        data: data,
                        borderColor: "rgba(255, 99, 132, 0.5)"
                    }
                ]
            }
        };
        updatedValue['avg-rating-chart-by-day'] = {
            'title': "Average Rating Chart By Weekday",
            'data': {
                datasets: [
                    {
                        label: "",
                        data: ratingByWeekday,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 205, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(201, 203, 207, 0.2)'
                        ]
                    }
                ]
            }
        };
        stats = {
            ...stats,
            ...updatedValue
        };
        setStats(stats);
    }

    function processSleepStats(result) {
        const firstDayOfMonth = dayjs(startDate).startOf('month');
        const lastDayOfMonth = dayjs(startDate).endOf('month');
        const records = result.data.records;
        const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
        const sleepRatingData = {};
        const totalSleepHours = {};
        const sleepRatingDataByDays = {};
        labels.map((key) => {
            sleepRatingData[key] = 0;
            totalSleepHours[key] = 0;
            sleepRatingDataByDays[key] = []
        });
        const averageSleepHours = [];
        const utcOffset = dayjs().utcOffset() * 60
        records.map((item) => {
            const addedOnDay = dayjs((item.sleep_start_time+utcOffset) * 1000);
            if (item.sleep_rating) {
                sleepRatingDataByDays[addedOnDay.date()].push(item.sleep_rating)
            }

            if (item.sleep_start_time && item.sleep_end_time) {
                totalSleepHours[addedOnDay.date()] = ((item.sleep_end_time - item.sleep_start_time) / 3600);
                averageSleepHours.push((item.sleep_end_time - item.sleep_start_time) / 3600)
            }
        });
        Object.keys(sleepRatingDataByDays).map((key) => {
            if (sleepRatingDataByDays[key].length > 0) {
                sleepRatingData[key] = sleepRatingDataByDays[key].reduce((a, b) => a + b, 0) / sleepRatingDataByDays[key].length
            }
        })

        const updatedValue = {}
        updatedValue['sleep-rating-chart'] = {
            'title': "Quality of sleep",
            'data': {
                datasets: [
                    {
                        label: "",
                        data: sleepRatingData,
                        borderColor: "rgba(255, 99, 132, 0.5)"
                    }
                ]
            }
        };
        updatedValue['sleep-hours'] = {
            'title': "Hours of sleep",
            'data': {
                datasets: [
                    {
                        label: "",
                        data: totalSleepHours,
                        borderColor: "rgba(255, 99, 132, 0.5)"
                    }
                ]
            }
        };
        updatedValue['average-hours-current-month'] = {
            'title': "Average hours current month",
            'data': parseFloat(averageSleepHours.reduce((a, b) => a + b, 0) / averageSleepHours.length).toFixed(2)
        };
        stats = {
            ...stats,
            ...updatedValue
        };
        setStats(stats);
    }

    function processPreviousSleepStats(result) {
        const averageSleepHours = [];
        const records = result.data.records;
        records.map((item) => {
            if (item.sleep_start_time && item.sleep_end_time) {
                averageSleepHours.push((item.sleep_end_time - item.sleep_start_time) / 3600)
            }
        });
        const updatedValue = {}
        updatedValue['average-hours-previous-month'] = {
            'title': "Average hours previous month",
            'data': parseFloat(averageSleepHours.reduce((a, b) => a + b, 0) / averageSleepHours.length).toFixed(2)
        };
        stats = {
            ...stats,
            ...updatedValue
        };
        setStats(stats);
    }

    function getRatingStats(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=rating&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
        return axios.get(url);
    }

    function getCustomSectionRatingStats(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=custom_rating&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
        return axios.get(url);
    }

    function getCustomOptionsStats(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=custom_options_stats&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
        return axios.get(url);
    }

    function processRatingStats(result, date) {
        const data = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        };
        let totalEnteries = 0;
        if (result.data.records) {
            result.data.records.map((item) => {
                data[item.rating] = item.total;
                totalEnteries = totalEnteries + parseInt(item.total);
            });
        }

        const percentData = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        };
        if (result.data.records) {
            result.data.records.map((item) => {
                percentData[item.rating] = (parseInt(item.total) / totalEnteries) * 100;
            });
        }

        const updatedValue = {}
        updatedValue['rating'] = {
            'title': "Rating Count",
            'data': data
        };
        updatedValue['rating-likelihood'] = {
            'title': "Rating Likelihood of ratings",
            'data': percentData
        };
        tableStats = {
            ...tableStats,
            ...updatedValue
        };
        setTableStats(tableStats);
    }

    function processCustomRatingStats(result, date) {
        const data = {};
        let totalEnteries = 0;
        if (result.data.records) {
            result.data.records.map((item) => {
                data[item.name] = data[item.name] || { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
                data[item.name][item.rating] = item.total;
                totalEnteries = totalEnteries + parseInt(item.total);
            });
        }

        const percentData = {};
        if (result.data.records) {
            result.data.records.map((item) => {
                percentData[item.name] = percentData[item.name] || { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
                percentData[item.name][item.rating] = (parseInt(item.total) / totalEnteries) * 100;
            });
        }
        const updatedValue = {}
        const categoryKeys = []
        Object.keys(data).map((key) => {
            updatedValue[key] = {
                'title': key,
                'data': data[key]
            };
            updatedValue[key + '-likelihood'] = {
                'title': "Likelihood of" + key,
                'data': percentData[key]
            };
            categoryKeys.push(key)
            categoryKeys.push(key + '-likelihood')
        })
        console.log("categoryKeyscategoryKeyscategoryKeys", categoryKeys)
        setCustomCategoryKeys(categoryKeys)
        tableStats = {
            ...tableStats,
            ...updatedValue
        };
        setTableStats(tableStats);
    }

    function processCustomOptionsStats(result, date) {
        const data = {};
        const customSectionKeys = [];
        if (result.data.records) {
            result.data.records.map((item) => {
                data[item.section_name] = data[item.section_name] || {};
                data[item.section_name][item.name] = item.total;
            });
        }
        const updatedValue = {}
        Object.keys(data).map(key => {
            updatedValue[key] = {
                'title': key,
                'data': data[key]
            };
        })

        tableStats = {
            ...tableStats,
            ...updatedValue
        };
        setTableStats(tableStats)
        setCustomOptionsSectionKeys(Object.keys(data))
    }

    function getCategoryStats(category, date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=options&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}&category=${category}`;
        return axios.get(url);
    }

    function processCategoryStats(result, category, date) {
        const data = {};
        if (result.data.records) {
            result.data.records.map((item) => {
                data[item.name] = item.total;
            });
        }
        const updatedValue = {}
        updatedValue[category] = {
            'title': categories[category],
            'data': data
        };
        tableStats = {
            ...tableStats,
            ...updatedValue
        };
        setTableStats(tableStats)
    }

    function getTagStats(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=tags&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
        return axios.get(url);
    }

    function getSleepStats(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_sleep?filter=sleep_start_time,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}`;
        return axios.get(url);
    }

    function getPreviousMonthSleepStats(date) {
        const firstDayOfMonth = dayjs(date).startOf('month').subtract(1, 'month');
        const lastDayOfMonth = dayjs(firstDayOfMonth).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_sleep?filter=sleep_start_time,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}`;
        return axios.get(url);
    }

    function processTagStats(result, date) {
        const data = {};
        if (result.data.records) {
            result.data.records.map((item) => {
                data[item.name] = item.total;
            });
        }
        const updatedValue = {}
        updatedValue["tags"] = {
            'title': "Tags",
            'data': data
        };
        tableStats = {
            ...tableStats,
            ...updatedValue
        };
        setTableStats(tableStats)
    }

    function onDateChange(date) {
        setStartDate(date)
        loadStats(date, true)
    }

    function onOptionChange(value, target) {
        var category = target.options[target.selectedIndex].parentNode.id;
        setSelectedCategory(category)
        setSelectedOption(value)
    }

    function fetchOftenTogetherStats(selectedDate) {
        if (selectedCategory.length == 0 || selectedOption.length == 0) {
            return
        }
        const firstDayOfMonth = dayjs(selectedDate || startDate).startOf('month');
        const lastDayOfMonth = dayjs(selectedDate || startDate).endOf('month');
        if (selectedCategory == "rating" || customRatingOptions[selectedCategory]) {
            const promises = []
            let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-rating&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[customRatingOptions[selectedCategory] ? 'customRating' : selectedCategory]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
            if (customRatingOptions[selectedCategory]) {
                url += `&customSectionId=${customRatingOptions[selectedCategory].id}`;
            }
            promises.push(axios.get(url))
            if (customRatingOptions[selectedCategory]) {
                promises.push(axios.get(`${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-custom-rating&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[customRatingOptions[selectedCategory] ? 'customRating' : selectedCategory]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}&customSectionId=${customRatingOptions[selectedCategory].id}`))
            }
            Promise.all(promises).then((result) => {
                const updatedValue = {}
                if (result[0].data.records) {
                    stats = {}
                    result[0].data.records.map((record) => {
                        stats[record.name] = record.count;
                    });
                    updatedValue['often-together-with-rating'] = {
                        'title': `Often Together with ${selectedCategory}`,
                        'data': stats
                    };
                }
                if (result[1] && result[1].data.records) {
                    stats = {}
                    result[1].data.records.map((record) => {
                        stats[record.rating] = record.count;
                    });
                    updatedValue['often-together-with-custom-rating'] = {
                        'title': `Often Together with Rating`,
                        'data': stats
                    };
                }
                tableStats = {
                    ...tableStats,
                    ...updatedValue
                };
                setTableStats(tableStats);
            });
        } else {
            const promises = []
            const categoryKey = categories[selectedCategory] ? selectedCategory : 'custom'

            if (oftenTogetherCategoryMapping[categoryKey].indexOf("rating") > -1) {
                let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-rating-with-option&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[categoryKey]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
                promises.push(axios.get(url))
                promises.push(axios.get(`${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-custom-rating-with-option&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[categoryKey]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`))
            }
            let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[categoryKey]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
            promises.push(axios.get(url))

            Promise.all(promises).then((result) => {
                const updatedValue = {}
                const ratingStats = {}
                const customRatingStats = {}
                if (oftenTogetherCategoryMapping[categoryKey].indexOf("rating") > -1) {
                    if (result[0].data.records) {
                        result[0].data.records.map((record) => {
                            ratingStats[record.rating] = record.count;
                        });
                        updatedValue['often-together-with-rating'] = {
                            'title': "Often Together with Rating",
                            'data': ratingStats
                        };
                    }
                    if (result[1] && result[1].data.records) {
                        result[1].data.records.map((record) => {
                            customRatingStats[record.rating] = record.count;
                        });
                        updatedValue['often-together-with-custom-rating'] = {
                            'title': "Often Together with Custom Option Rating",
                            'data': customRatingStats
                        };
                    }
                    if (result[2] && result[2].data.records) {
                        const stats = {}
                        result[2].data.records.map((record) => {
                            stats[record.name] = record.count;
                        });
                        updatedValue['often-together'] = {
                            'title': "Often Together",
                            'data': stats
                        };
                    }
                } else {
                    if (result[0].data.records) {
                        const stats = {}
                        result[0].data.records.map((record) => {
                            stats[record.name] = record.count;
                        });
                        updatedValue['often-together'] = {
                            'title': "Often Together",
                            'data': stats
                        };
                    }
                    updatedValue['often-together-with-rating'] = {
                        'title': "Often Together with Rating",
                        'data': {}
                    };
                    updatedValue['often-together-with-custom-rating'] = {
                        'title': "Often Together with Custom Option Rating",
                        'data': {}
                    };
                }

                tableStats = {
                    ...tableStats,
                    ...updatedValue
                };
                setTableStats(tableStats);
            })
        }
    }

    function getOptions() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options?join=custom_sections`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const options = {};
                records.map((item) => {
                    const category = item.category == "custom" ? item.custom_section_id.name : item.category
                    options[category] = options[category] || [];
                    options[category].push(item)
                });
                setOptions(options);
            }, error => {
                alert(error);
            });
    }

    function getCustomRatingOptions() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/custom_sections?filter=field_type,eq,rating`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const options = {};
                records.map((item) => {
                    options[item.name] = item;
                });
                setCustomRatingOptions(options);
            }, error => {
                alert(error);
            });
    }

    function fetchMoodStatsBySleepHours(date) {
        const utcOffset = dayjs().utcOffset() * 60
        const startDate = dayjs(date).startOf('month').subtract(1, 'day');
        const startTime = startDate.unix() + utcOffset;
        const endTime = dayjs(date).startOf('month').endOf('month').unix() + utcOffset
        const promises = []
        promises.push(axios.get(`${process.env.REACT_APP_API_URL}index.php/records/activity_sleep?filter=sleep_start_time,bt,${startTime},${endTime}`))
        promises.push(axios.get(`${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${startTime},${endTime}`))
        Promise.all(promises)
            .then((response) => {
                const sleepRecords = response[0].data.records;
                const activityRecords = response[1].data.records;
                const sleepRecordsByDate = {}
                const ratingBySleepHoursList = {
                    '1-4 hrs': [],
                    '5-6 hrs': [],
                    '7-8 hrs': [],
                    '8+ hours': []
                }
                const ratingBySleepHours = {
                    '1-4 hrs': 0,
                    '5-6 hrs': 0,
                    '7-8 hrs': 0,
                    '8+ hours': 0
                }
                sleepRecords.map((item) => {
                    const dateKey = dayjs((item.sleep_start_time + utcOffset) * 1000).startOf('day').unix()
                    sleepRecordsByDate[dateKey] = (item.sleep_end_time - item.sleep_start_time) / 3600
                });
                activityRecords.map((item) => {
                    const previousDay = dayjs((item.added_on + utcOffset) * 1000).startOf('day').subtract(1, 'day').unix()
                    if (sleepRecordsByDate[previousDay]) {
                        const sleepHours = sleepRecordsByDate[previousDay]
                        let key = "8+ hours";
                        if (sleepHours > 0 && sleepHours < 5) {
                            key = "1-4 hrs";
                        } else if (sleepHours >= 5 && sleepHours < 7) {
                            key = "5-6 hrs";
                        } else if (sleepHours >= 7 && sleepHours < 8) {
                            key = "7-8 hrs";
                        }
                        ratingBySleepHoursList[key].push(item.rating)
                    }
                });
                Object.keys(ratingBySleepHoursList).map((key) => {
                    if (ratingBySleepHoursList[key].length > 0) {
                        ratingBySleepHours[key] = ratingBySleepHoursList[key].reduce((a, b) => a + b, 0) / ratingBySleepHoursList[key].length
                    }
                })
                const updatedValue = {}
                updatedValue['avg-rating-chart-by-sleep-hours'] = {
                    'title': "Average mood by hours of sleep",
                    'data': {
                        datasets: [
                            {
                                label: "",
                                data: ratingBySleepHours,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(255, 205, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)'
                                ]
                            }
                        ]
                    }
                };
                stats = {
                    ...stats,
                    ...updatedValue
                };
                setStats(stats);
            });
    }

    return (
        <>
            <Stack direction="horizontal" gap={5} className="p-5">
                <Button variant="secondary" onClick={() => { const date = dayjs(startDate).subtract(1, 'month'); onDateChange(date); }}>Prev</Button>
                {dayjs(startDate).format('MMM')}
                <Button disabled={dayjs(startDate).month() == dayjs().month()} variant="secondary" onClick={() => { const date = dayjs(startDate).add(1, 'month'); onDateChange(date); }}>Next</Button>

                <Form.Select onChange={(e) => { onOptionChange(e.target.value, e.target) }} className="mx-5" style={{ width: "300px" }}>
                    <optgroup label="">
                        <option value="0">---Select--</option>
                    </optgroup>
                    <optgroup id="rating" label="Rating">
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                        <option value="4">Four</option>
                        <option value="5">Five</option>
                    </optgroup>
                    {Object.keys(options).map(category =>
                        <optgroup key={category} id={category} label={`${categories[category] || category}`}>
                            {options[category] && options[category].map(option =>
                                <option key={option.id} value={`${option.id}`}>{option.name}</option>
                            )}
                        </optgroup>
                    )}
                    {Object.keys(customRatingOptions).map((key) =>
                        <optgroup id={key} label={key}>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                            <option value="4">Four</option>
                            <option value="5">Five</option>
                        </optgroup>
                    )}
                </Form.Select>
            </Stack>
            <Stack gap={2}>
                {graphKeys.map((row, index) =>
                    <Stack direction="horizontal" gap={5} key={index}>
                        {row.map((category, index) =>
                            <Fragment key={index}>
                                {stats[category] &&
                                    <ChartView chartType={dateGraphs.indexOf(category) > -1 ? 'line' : 'bar'} width={dateGraphs.indexOf(category) > -1 ? 800 : 400} key={category} text={stats[category].title} data={stats[category].data} />
                                }
                            </Fragment>
                        )}
                    </Stack>
                )}
                {[...categoryKeys, ...[customOptionsSectionKeys]].map((row, index) =>
                    <Stack direction="horizontal" gap={5} key={index}>
                        {row.map((category, index) =>
                            <Fragment key={index}>
                                {tableStats[category] && Object.keys(tableStats[category].data).length > 0 &&
                                    <Stack key={category}>
                                        <h5>{tableStats[category].title}</h5>
                                        <Table striped bordered hover style={{ maxWidth: '400px' }}>
                                            <tbody>
                                                {Object.keys(tableStats[category].data).map((key) =>
                                                    <tr key={key}>
                                                        <td>{key}</td>
                                                        <td>{tableStats[category].data[key]}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Stack>
                                }
                            </Fragment>
                        )}
                    </Stack>
                )}
                {customCategoryKeys.map((category, index) =>
                    <Stack direction="horizontal" gap={5} key={index}>
                        {tableStats[category] && Object.keys(tableStats[category].data).length > 0 &&
                            <Stack key={category}>
                                <h5>{tableStats[category].title}</h5>
                                <Table striped bordered hover style={{ maxWidth: '400px' }}>
                                    <tbody>
                                        {Object.keys(tableStats[category].data).map((key) =>
                                            <tr key={key}>
                                                <td>{key}</td>
                                                <td>{tableStats[category].data[key]}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Stack>
                        }
                    </Stack>
                )}
                <Stack>
                    <Table striped bordered hover style={{ maxWidth: '400px' }}>
                        <tbody>
                            {stats['average-hours-current-month'] &&
                                <tr><td>{stats['average-hours-current-month']['title']}</td><td>{stats['average-hours-current-month']['data']}</td></tr>
                            }
                            {stats['average-hours-previous-month'] &&
                                <tr><td>{stats['average-hours-previous-month']['title']}</td><td>{stats['average-hours-previous-month']['data']}</td></tr>
                            }
                        </tbody>
                    </Table>
                </Stack>
            </Stack>
        </>
    );
}

export default Stats;