import React, { useEffect, useState } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js"
import { Bar, Line } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

function ChartView(props) {
    const [data, setData] = useState({});
    const [options, setOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: "top"
            },
            title: {
                display: true,
                text: "Testing"
            }
        },
        maintainAspectRatio: false
    });

    useEffect(() => {
        options.plugins.title.text = props.text;
        setOptions(options);
        setData(props.data);
    }, []);

    return (
        <div style={{ width: props.width ? props.width : '400px', height: props.height ? props.height : '400px' }}>
            {Object.keys(data).length > 0 &&
                <>
                    {props.chartType == "line" &&
                        <Line options={options}  data={data} />
                    }

                    {props.chartType == "bar" &&
                        <Bar options={options}  data={data} />
                    }
                </>
            }
        </div>
    );
}

export default ChartView;

