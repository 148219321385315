import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import React, { Component }  from 'react';
import { useNavigate } from "react-router-dom"
import * as dayjs from "dayjs";

function ManageReminders() {
    const navigate = useNavigate()
    const [reminders, setReminders] = useState([]);
    
    useEffect(() => {
        getReminders()
    }, []);

    function getReminders() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders?filter=type,nin,goal,habit`;
        axios
            .get(url)
            .then((result) => {
                setReminders(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function deleteReminder(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders/${id}`;
        axios
            .delete(url)
            .then((res) => {
                getReminders()
            }, error => {
                alert(error);
            });
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Button onClick={() => { navigate(`/reminder`) }}>Set Reminder</Button>
                <Stack gap={2}>
                    {reminders && reminders.map((item) =>
                        <Stack key={item.id} gap={2} direction="horizontal">
                            <strong className="me-auto">{dayjs(item.time * 1000).format("h:mm A")}</strong>
                            <Button variant="primary" onClick={() => { navigate(`/reminder/${item.id}`) }}>Edit</Button>
                            <Button variant="danger" onClick={() => { deleteReminder(item.id) }}>Delete</Button>
                        </Stack>
                    )}
                </Stack>
            </Container>
        </div>
    );
}

export default ManageReminders;