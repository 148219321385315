import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Table, Toast } from 'react-bootstrap';
import { Fragment, useEffect, useState, useRef } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import ReactDatePicker from "react-datepicker";
import * as dayjs from "dayjs";
import IconList from './iconList';
import { Editor } from '@tinymce/tinymce-react';


function AddEditGoal(props) {
    const [displayToast, enableToastDisplay] = useState(false);
    const [frequencyType, setFrequencyType] = useState("x-days");
    const [frequency, setFrequency] = useState({
        'x-days': 0,
        'daily': "Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday"
    });
    const [totalDaysPerWeek, setTotalDaysPerWeek] = useState({
        'x-days': 0,
        'daily': 7
    });
    const [selectedIconId, setSelectedIconId] = useState("")
    const [name, setName] = useState("")
    const [details, setDetails] = useState("")
    const [category, setCategory] = useState("")
    const [enableReminder, setEnableReminder] = useState(false)
    const [reminderTime, setReminderTime] = useState(new Date())
    const [reminderId, setReminderId] = useState(null)

    const [goalCompletedStats, setGoalCompletedStats] = useState(0)
    const [previousWeelGoalCompletedStats, setPreviousWeelGoalCompletedStats] = useState(0)

    const [currentStreak, setCurrentStreak] = useState(0)
    const [longestStreak, setLongestStreak] = useState(0)
    const [goalType, setGoalType] = useState("")
    const [goalCategories, setGoalCategories] = useState([]);
    const [selectedDayGoalActivity, setSelectedDayGoalActivity] = useState(null);
    const [uploadedFile, setUploadedFile] = useState("");
    const [fullWidthImage, setFullWidthImage] = useState("");
    const [isShortTerm, setIsShortTerm] = useState(false);
    const [shortTermGoalDays, setShortTermGoalDays] = useState("")

    const frequencyTypes = { 'x-days': "x days per week", 'daily': 'daily' };

    const { id } = useParams()
    const { date } = useParams()
    let [calender, setCalender] = useState({});
    let [calenderDate, setCalenderDate] = useState();

    const editorRef = useRef(null);
    useEffect(() => {
        if (id) {
            getGoalInfo(id)
            getGoalCategoryList()
            getGoalActivityForSelectedDate(id)
            getReminderInfo(id)
            setCalenderDate(date || new Date())
        }
    }, []);

    useEffect(() => {
        loadCalendar()
    }, [calenderDate]);

    useEffect(() => {
        if (id) {
            getGoalCompletionStats()
            getPreviousWeekGoalCompletionStats()
            getLongestStreakStats()
        }
    }, [frequencyType]);

    function loadCalendar() {
        let selectedDate = dayjs(calenderDate)
        const firstDayOfMonth = selectedDate.startOf('month');
        let lastDayOfMonth = selectedDate.endOf('month');
        if (dayjs(new Date()).diff(lastDayOfMonth) < 0) {
            lastDayOfMonth = dayjs(new Date())
        }
        const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
        const items = {}
        labels.map((key) => {
            items[key] = 0;
        });

        setCalender(items)
    }

    function getGoalCategoryList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_categories`;
        axios
            .get(url)
            .then((result) => {
                console.log("result.data.records", result.data.records)
                setGoalCategories(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function getGoalInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals/${id}`;
        axios
            .get(url)
            .then((result) => {
                const goalInfo = result.data;
                const freqType = goalInfo.frequency_type || '';
                setSelectedIconId(goalInfo.icon_id);
                setName(goalInfo.name);
                setDetails(goalInfo.details)
                const updatedValue = { ...frequency };
                updatedValue[freqType] = goalInfo.frequency;
                setFrequency(updatedValue);
                setFrequencyType(freqType);
                setCategory(goalInfo.category)
                setUploadedFile(goalInfo.iconImage)
                setFullWidthImage(goalInfo.fullWidthImage)
                console.log("goalInfo.goalTypegoalInfo.goalType", goalInfo.goalType, goalInfo.goalType == "shortTerm")
                setIsShortTerm(goalInfo.goalType == "shortTerm")
                setTotalDaysPerWeek({
                    'x-days': updatedValue['x-days'] || 0,
                    'daily': updatedValue['daily'] ? updatedValue['daily'].split(",").length : 7,
                });
                setGoalType(goalInfo.type);
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals${(id != null ? `/${id}` : '')}`;
        const data = {
            name: name,
            details: editorRef.current.getContent(),
            type: goalType.length ? goalType : (props.isManageDefaultGoalView ? "default" : "user"),
            is_active: frequency[frequencyType] != null,
            category: category,
            goalType: isShortTerm ? "shortTerm" : "ongoing"
        };
        if (selectedIconId && selectedIconId.length > 0) {
            data["icon_id"] = selectedIconId
        }
        if (uploadedFile && uploadedFile.length > 0) {
            data["iconImage"] = uploadedFile
        }
        if (frequencyType && frequencyType.length > 0) {
            data["frequency_type"] = frequencyType
        }
        if (frequency[frequencyType] && frequency[frequencyType].length > 0) {
            data["frequency"] = frequency[frequencyType]
        }
        if (shortTermGoalDays && shortTermGoalDays.length > 0) {
            data["shortTermGoalDays"] = shortTermGoalDays
        }
        if (fullWidthImage && fullWidthImage.length > 0) {
            data["fullWidthImage"] = fullWidthImage
        }
        if (id) {
            axios
                .put(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        } else {
            axios
                .post(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        }
    }

    function markGoalCompleted() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity`;
        if (selectedDayGoalActivity) {
            url = url + `/${selectedDayGoalActivity.id}`;
        }
        const data = {
            goal_id: id,
            added_on: dayjs(date || new Date()).unix()
        };

        const promise = selectedDayGoalActivity ? axios.delete(url) : axios.post(url, data, {});
        promise
            .then((res) => {
                enableToastDisplay(true);
                getGoalCompletionStats();
                getLongestStreakStats();
                getGoalActivityForSelectedDate(id);
            }, error => {
                alert(error);
            });
    }

    function getGoalCompletionStats() {
        let selectedDate = dayjs(date || new Date())
        const startRange = selectedDate.startOf('week');
        const endRange = selectedDate.endOf('week');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}&filter=goal_id,eq,${id}`;
        axios
            .get(url)
            .then((res) => {
                setGoalCompletedStats(res.data.records.length)
            }, error => {
                alert(error);
            });
    }

    function getGoalActivityForSelectedDate(id) {
        let selectedDate = dayjs(date || new Date())
        console.log("getGoalActivityForSelectedDate", selectedDate, date, date || new Date(), dayjs('2018-04-13 19:18'), dayjs('2018-04-13'), dayjs('12-01-2022'))
        const startRange = selectedDate.startOf('day');
        const endRange = selectedDate.endOf('day');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}&filter=goal_id,eq,${id}`;
        axios
            .get(url)
            .then((res) => {
                if (res.data.records) {
                    setSelectedDayGoalActivity(res.data.records[0])
                }
            }, error => {
                alert(error);
            });
    }

    function getPreviousWeekGoalCompletionStats() {
        let selectedDate = dayjs(date || new Date())
        const startRange = selectedDate.startOf('week').subtract(7, 'day');
        const endRange = selectedDate.startOf('week').subtract(1, 'day');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity?filter=added_on,bt,${startRange.unix()},${endRange.unix()}&filter=goal_id,eq,${id}`;
        axios
            .get(url)
            .then((res) => {
                setPreviousWeelGoalCompletedStats(res.data.records.length)
            }, error => {
                alert(error);
            });
    }

    function getLongestStreakStats() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_activity?filter=goal_id,eq,${id}&order=added_on,asc`;
        let updatedLongestStreak = 0
        let currentLongestStreak = 0
        let streak = 0
        axios
            .get(url)
            .then((result) => {
                var previousAddedOn = null
                result.data.records.map((record) => {
                    console.log("date diff", dayjs(record.added_on * 1000).diff(dayjs(previousAddedOn * 1000), 'day'))
                    if (previousAddedOn == null || dayjs(record.added_on * 1000).diff(dayjs(previousAddedOn * 1000), 'day') <= 1) {
                        previousAddedOn = record.added_on
                        streak++
                    } else {
                        updatedLongestStreak = updatedLongestStreak < streak ? streak : updatedLongestStreak
                        currentLongestStreak = streak
                        streak = 1
                    }
                })
                currentLongestStreak = streak > 0 ? streak : currentLongestStreak;
                updatedLongestStreak = updatedLongestStreak < streak ? streak : updatedLongestStreak
                setLongestStreak(updatedLongestStreak)
                setCurrentStreak(currentLongestStreak)
            }, error => {
                alert(error);
            });
    }

    function onNameChange(event) {
        setName(event.target.value)
    }

    function onFrequencyChange(dayIndex, event) {
        const updatedFrequency = frequency[frequencyType] ? frequency[frequencyType].split(",") : []
        if (event.target.checked) {
            updatedFrequency.push(dayIndex)
        } else {
            updatedFrequency.splice(updatedFrequency.indexOf(dayIndex), 1)
        }
        const updatedValue = { ...frequency };
        updatedValue[frequencyType] = updatedFrequency.join(",");
        console.log(updatedValue)
        setFrequency(updatedValue)
        setTotalDaysPerWeek({
            'x-days': updatedValue['x-days'] || 0,
            'daily': updatedValue['daily'] ? updatedValue['daily'].split(",").length : 7,
        })
    }

    function createReminder() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders${reminderId ? `/${reminderId}` : ''}`;
        const data = {
            time: dayjs(reminderTime).unix(),
            type: 'goal',
            type_id: id
        };
        const promise = reminderId ? axios.put(url, data, {}) : axios.post(url, data, {});
        promise.then((res) => {
            enableToastDisplay(true);
        }, error => {
            alert(error);
        });
    }

    function getReminderInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders?filter=type,eq,goal&filter=type_id,eq,${id}`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                if (records && records.length > 0) {
                    setEnableReminder(true);
                    setReminderTime(records[0].time * 1000);
                    setReminderId(records[0].id)
                }
            }, error => {
                alert(error);
            });
    }

    function updateFrequency(value) {
        const updatedValue = { ...frequency };
        updatedValue[frequencyType] = value;
        console.log(updatedValue)
        setFrequency(updatedValue)
        setTotalDaysPerWeek({
            'x-days': updatedValue['x-days'] || 0,
            'daily': updatedValue['daily'] ? updatedValue['daily'].split(",").length : 7,
        })
    }

    function spliceIntoChunks(daysAA, chunkSize) {
        const arr = Object.keys(daysAA);
        const res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }

        return res;
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Changes Saved successfully.</strong>
                    </Toast.Header>
                </Toast>
                {id &&
                    <>
                        <h2>Stats</h2>
                        <Stack>
                            <h6>Goal Completion Stats: {goalCompletedStats} out of {totalDaysPerWeek[frequencyType]}</h6>
                            <h6>success rate: {goalCompletedStats > 0 ? ((goalCompletedStats / totalDaysPerWeek[frequencyType]) * 100) : 0}%</h6>
                            <h6>previous week success rate: {previousWeelGoalCompletedStats > 0 ? ((previousWeelGoalCompletedStats / totalDaysPerWeek[frequencyType]) * 100) : 0}%</h6>
                            <h6>Current Streak: {currentStreak}</h6>
                            <h6>Longest Streak: {longestStreak}{goalType}{props.isManageDefaultGoalView ? "tes": "no"}</h6>
                        </Stack>
                    </>
                }
                <Form>
                    {(goalType == "user" || (!props.isManageDefaultGoalView || false)) &&
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name" value={name} onChange={onNameChange} />
                            </Form.Group>
                            {/* {goalCategories.length > 0 &&
                                <Form.Group className="mb-3">
                                    <Form.Label>Goal Category</Form.Label>
                                    <Form.Select aria-label="" onChange={(event) => { const category = event.target.value; setCategory(category); }} value={category}>
                                        <option key="" value="">--Select--</option>
                                        {goalCategories.map((category) => {
                                            return (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            } */}
                            <IconList selectedIconId={selectedIconId} onSelectedIconChange={(iconId) => { setSelectedIconId(iconId) }} />
                        </>
                    }
                    {(goalType == "default" || props.isManageDefaultGoalView) &&
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name" value={name} onChange={onNameChange} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Details</Form.Label>
                                <Editor
                                    apiKey='your-api-key'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={details}
                                    init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Icon Image</Form.Label>
                                <FileUploader onCompletionHandler={(files) => { setUploadedFile(files[0].path) }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {uploadedFile && uploadedFile.length > 0 &&
                                    <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + uploadedFile} />
                                }
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Full width Image</Form.Label>
                                <FileUploader onCompletionHandler={(files) => { setFullWidthImage(files[0].path) }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {fullWidthImage && fullWidthImage.length > 0 &&
                                    <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + fullWidthImage} />
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Category</Form.Label>
                                    <Form.Select aria-label="" onChange={(event) => { const category = event.target.value; setCategory(category); }} value={category}>
                                        <option key="" value="">--Select--</option>
                                        {goalCategories.map((category) => {
                                            return (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            );
                                        })}
                                    </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" defaultChecked={isShortTerm} checked={isShortTerm} onChange={(event) => { setIsShortTerm(event.target.checked); }} label="Is short term goal?" />
                            </Form.Group>
                            {isShortTerm &&
                                <Form.Group className="mb-3">
                                    <Form.Label>Number of days</Form.Label>
                                    <Form.Control type="text" placeholder="Enter days" value={shortTermGoalDays} onChange={(event) => { setShortTermGoalDays(event.target.value) }} />
                                </Form.Group>
                            }
                        </>
                    }
                    <Form.Group className="mb-3">
                        <Form.Label>Frequency type</Form.Label>
                        <Form.Select aria-label="" onChange={(event) => { setFrequencyType(event.target.value) }} value={frequencyType}>
                            <option value="">--Select frequencyType--</option>
                            {Object.keys(frequencyTypes).map((key) => {
                                return (
                                    <option key={key} value={key}>{frequencyTypes[key]}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>

                    {frequencyType == "x-days" &&
                        <Form.Group className="mb-3">
                            <Form.Label>No of days</Form.Label>
                            <Form.Control type="text" value={frequency[frequencyType]} onChange={(event) => { updateFrequency(event.target.value) }} />
                        </Form.Group>
                    }

                    {frequencyType == "daily" &&
                        <Form.Group className="mb-3">
                            <Form.Label>Days of the week</Form.Label>
                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((key, index) => {
                                return (
                                    <Form.Group key={key} className="mb-3" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" defaultChecked={frequency[frequencyType] && frequency[frequencyType].split(",").indexOf(key) > -1} name={key} onChange={(evt) => onFrequencyChange(key, evt)} value={index} label={key} />
                                    </Form.Group>
                                );
                            })}
                        </Form.Group>
                    }

                    <Form.Check
                        defaultChecked={enableReminder}
                        checked={enableReminder}
                        onChange={(event) => setEnableReminder(event.target.checked)}
                        type="switch"
                        id="custom-switch"
                        label="Enable Reminders"
                    />
                    {enableReminder &&
                        <Stack direction='horizontal' gap={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Time</Form.Label>
                                <ReactDatePicker
                                    selected={reminderTime}
                                    onChange={(date) => { setReminderTime(date) }}
                                    showTimeSelect
                                    timeIntervals={15}
                                    showTimeSelectOnly
                                    dateFormat="h:mm aa"
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={createReminder}>
                                Set Reminder
                            </Button>
                        </Stack>
                    }


                    <Stack direction='horizontal' gap={3}>
                        <Button variant="primary" onClick={onSubmitHandler}>
                            Submit
                        </Button>
                        {id &&
                            <Button variant={selectedDayGoalActivity ? "danger" : "success"} onClick={markGoalCompleted}>
                                {selectedDayGoalActivity ? "Mark as in-complete" : "Mark as complete"}
                            </Button>
                        }
                    </Stack>
                </Form>
                <Stack direction="horizontal" className="m-5" gap={5}>
                    <Button variant="secondary" onClick={() => { const date = dayjs(calenderDate).subtract(1, 'month'); setCalenderDate(date); }}>Prev</Button>
                    {dayjs(calenderDate).format('MMM')}
                    <Button disabled={dayjs(calenderDate).month() == dayjs().month()} variant="secondary" onClick={() => { const date = dayjs(calenderDate).add(1, 'month'); setCalenderDate(date); }}>Next</Button>
                </Stack>
                <Table striped bordered className="m-5">
                    <tbody>
                        {calender && spliceIntoChunks(calender, 7).map((result, key) =>
                            <tr key={key}>
                                {result.map((item) =>
                                    <td key={item} onClick={() => { window.location.href = `${window.location.origin}/goal/${id}/${dayjs(calenderDate).startOf('month').add(item - 1, 'day').format('YYYY-MM-DD')}` }} style={{ cursor: "pointer", backgroundColor: 'green' }}>{item}</td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    );
}

export default AddEditGoal;