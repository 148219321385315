import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

function IconList(props) {
    const [iconList, setIconList] = useState([]);
    const [selectedIconId, setSelectedIconId] = useState("")
    const [iconSearchTerm, setIconSearchTerm] = useState("")
    const [iconCategories, setIconCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("")

    useEffect(() => {
        getIconCategoryList()
        getIconList()
    }, []);

    useEffect(() => {
        if (props.selectedIconId) {
            setSelectedIconId(props.selectedIconId);
        }
    }, [props.selectedIconId]);

    function getIconCategoryList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/icon_categories`;
        axios
            .get(url)
            .then((result) => {
                setIconCategories(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function getIconList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/icons?order=category_id,asc&join=icon_categories`;
        axios
            .get(url)
            .then((result) => {
                setIconList(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function convertEmoji(code) {
        // Adding a hack.
        return '\u{1F618}'.replace("1F618", code)
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Form.Group className="mb-3">
                    <Form.Label>Search Icon</Form.Label>
                    <Form.Control type="text" placeholder="Enter term" value={iconSearchTerm} onChange={(event) => { setIconSearchTerm(event.target.value) }} />
                </Form.Group>
                {iconList && iconList.map((item) =>
                    <>
                        {iconSearchTerm.length > 0 && item.tags && item.tags.toLowerCase().includes(iconSearchTerm.toLowerCase()) &&
                            <Form.Group key={item.id} className="mb-3">
                                <Form.Check type="radio" defaultChecked={selectedIconId == item.id} inline name="icon" onChange={(evt) => props.onSelectedIconChange(item.id, evt)} value={item.id} />
                                <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + item.path} />
                            </Form.Group>
                        }
                    </>
                )}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Category</Form.Label>
                    <Form.Select aria-label="" value={selectedCategory} onChange={(evt) => setSelectedCategory(evt.target.value)}>
                        <option key="" value="">Select Category</option>
                        {iconCategories.map((category) => {
                            return (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
                {iconList && iconList.map((item, index) =>
                    <Stack>
                        {((item.category_id && item.category_id.id == selectedCategory) || !selectedCategory) &&
                            <>
                                {item.category_id && (index == 0 || ( iconList[index - 1].category_id && item.category_id.id != iconList[index - 1].category_id.id)) &&
                                    <h5>{item.category_id.name}</h5>
                                }
                                <Form.Group key={item.id} className="mb-3">
                                    <Form.Check type="radio" checked={selectedIconId == item.id} inline name="icon" onChange={(evt) =>  { setSelectedIconId(item.id);props.onSelectedIconChange(item.id, evt) }} value={item.id} />
                                    {item.path && item.path.length > 0 &&
                                        <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + item.path} />
                                    }
                                    {item.code &&
                                    <p style={{ display: 'inline' }}>{convertEmoji(item.code)}</p>                                    
                                    }
                                </Form.Group>
                            </>
                        }
                    </Stack>
                )}
            </Container>
        </div>
    );
}

export default IconList;