import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Table, Toast } from 'react-bootstrap';
import { useEffect, useState, useRef } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Editor } from '@tinymce/tinymce-react';

function AddEditDefaultGoal(props) {
    const [displayToast, enableToastDisplay] = useState(false);
    const [category, setCategory] = useState("");
    const [name, setName] = useState("")
    const [details, setDetails] = useState("")
    const [shortTermGoalDays, setShortTermGoalDays] = useState("")
    const [goalCategories, setGoalCategories] = useState([]);
    const [isShortTerm, setIsShortTerm] = useState(false);
    let [calender, setCalender] = useState({});
    const [uploadedFile, setUploadedFile] = useState("");
    const [fullWidthImage, setFullWidthImage] = useState("");
    const editorRef = useRef(null);

    useEffect(() => {
        getGoalCategoryList()
    }, []);

    function getGoalCategoryList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_categories`;
        axios
            .get(url)
            .then((result) => {
                setGoalCategories(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function formatDateToYMMDD(date) {
        // Get the year, month, and day components of the date
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
      
        // Create the formatted date string
        const formattedDate = `${year}-${month}-${day} 00:00:00`;
      
        return formattedDate;
    }

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goals`;
        const data = {
            name: name,
            details: editorRef.current.getContent(),
            category: category,
            type: "default",
            goalType: isShortTerm ? "shortTerm" : "ongoing",
            startDate: formatDateToYMMDD(new Date())
        };
        if (uploadedFile && uploadedFile.length > 0) {
            data["iconImage"] = uploadedFile
        }
        if (fullWidthImage && fullWidthImage.length > 0) {
            data["fullWidthImage"] = fullWidthImage
        }
        if (shortTermGoalDays && shortTermGoalDays.length > 0) {
            data["shortTermGoalDays"] = shortTermGoalDays
        }
        axios
            .post(url, data, {})
            .then((res) => {
                enableToastDisplay(true);
            }, error => {
                alert(error);
            });
    }

    // function getCalendarDays() {
    //     let selectedDate = dayjs(date || new Date())
    //     const firstDayOfMonth = dayjs(date).startOf('month');
    //     const lastDayOfMonth = dayjs(date).endOf('month');
    //     const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
    //     const items = {}
    //     labels.map((key) => {
    //         items[key] = 0;
    //     });

    //     return items
    // }

    function spliceIntoChunks(daysAA, chunkSize) {
        const arr = Object.keys(daysAA);
        const res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }

        return res;
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Changes Saved successfully.</strong>
                    </Toast.Header>
                </Toast>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={name} onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>
                    <Editor
                        apiKey='your-api-key'
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={details}
                        init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                    <Form.Group className="mb-3">
                        <Form.Label>Category</Form.Label>
                        <Form.Select aria-label="" onChange={(event) => { const category = event.target.value; setCategory(category); }} value={category}>
                            <option key="" value="">--Select--</option>
                            {goalCategories.map((category) => {
                                return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" defaultChecked={isShortTerm} onChange={(event) => { setIsShortTerm(event.target.checked); }} label="Is short term goal?" />
                    </Form.Group>
                    {isShortTerm &&
                        <Form.Group className="mb-3">
                            <Form.Label>Number of days</Form.Label>
                            <Form.Control type="text" placeholder="Enter days" value={shortTermGoalDays} onChange={(event) => { setShortTermGoalDays(event.target.value) }} />
                        </Form.Group>
                    }
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Icon Image</Form.Label>
                        <FileUploader onCompletionHandler={(files) => { setUploadedFile(files[0].path) }} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {uploadedFile && uploadedFile.length > 0 &&
                            <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + uploadedFile} />
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Full width Image</Form.Label>
                        <FileUploader onCompletionHandler={(files) => { setFullWidthImage(files[0].path) }} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {fullWidthImage && fullWidthImage.length > 0 &&
                            <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + fullWidthImage} />
                        }
                    </Form.Group>
                    <Stack direction='horizontal' gap={3}>
                        <Button variant="primary" onClick={onSubmitHandler}>
                            Submit
                        </Button>
                    </Stack>
                    {/* <Table striped bordered className="m-5">
                        <tbody>
                            {calender && spliceIntoChunks(calender, 7).map((result, key) =>
                                <tr key={key}>
                                    {result.map((item) =>
                                        <td key={item} onClick={() => { window.location.href = `${window.location.origin}/habit/${id}/${dayjs(date || new Date()).startOf('month').add(item - 1, 'day').format('MM-DD-YYYY')}` }} style={{ cursor: "pointer", backgroundColor: calender[item] > 0 ? '#FF0000' : '' }}>{item}</td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </Table> */}
                </Form>
            </Container>
        </div>
    );
}

export default AddEditDefaultGoal;