import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

function AddTemplate(props) {
    const [displayToast, enableToastDisplay] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [name, setName] = useState("")
    const [uploadedFile, setUploadedFile] = useState("");
    const [templateType, setTemplateType] = useState("user");
    const [overrides, setOverrides] = useState({});
    const { id } = useParams()
    const isAdmin = props.isAdmin
    

    useEffect(() => {
        if (id) {
            getTemplateInfo(id)
        } else {
            setTemplateType(props.templateType || "user")
        }
    }, []);

    function getTemplateInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/templates/${id}?join=user_template_overrides`;
        axios
            .get(url)
            .then((result) => {
                const option = result.data
                const overrides = result.data["user_template_overrides"] ? result.data["user_template_overrides"][0] : [];
                setName(overrides ? overrides.name : option.name)
                setQuestions(overrides ? overrides.questions : option.questions)
                setTemplateType(option.type || "user")
                setUploadedFile(overrides ? overrides.icon_path : option.icon_path)
                if (overrides) {
                    setOverrides(overrides)
                }
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        const templateId = isAdmin ? id : overrides.id;
        const data = {
            name: name,
            questions: questions,
            icon_path: uploadedFile
        }
        let url = "";
        if (isAdmin || !id) {
            data.type = templateType
            url = `${process.env.REACT_APP_API_URL}index.php/records/templates${(templateId != null ? `/${templateId}` : '')}`;
        } else {
            data.template_id = id
            url = `${process.env.REACT_APP_API_URL}index.php/records/user_template_overrides${(templateId != null ? `/${templateId}` : '')}`;
        }
        
        ;
        if (templateId) {
            axios
                .put(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        } else {
            axios
                .post(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        }
    }

    function restoreDefault() {
        axios.delete(`${process.env.REACT_APP_API_URL}index.php/records/user_template_overrides/${overrides.id}`).then(_ => {
            getTemplateInfo(id)
        });
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Changes Saved successfully.</strong>
                    </Toast.Header>
                </Toast>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={name} onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Questions</Form.Label>
                        <Form.Control as="textarea" value={questions} placeholder="Questions" onChange={(event) => { setQuestions(event.target.value) }} />
                    </Form.Group>

                    {templateType == "default" &&
                        <>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Icon Image</Form.Label>
                                <FileUploader onCompletionHandler={(files) => { setUploadedFile(files[0].path) }} />
                            </Form.Group>
                            {uploadedFile.length > 0 &&
                                <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + uploadedFile} />
                            }
                        </>
                    }

                    <Stack direction='horizontal' className='mt-3' gap={3}>
                        <Button variant="success" onClick={onSubmitHandler}>
                            Submit
                        </Button>
                        {!isAdmin && overrides.id &&
                            <Button variant="primary" onClick={restoreDefault}>
                                Restore default
                            </Button>
                        }
                    </Stack>
                </Form>
            </Container>
        </div>
    );
}

export default AddTemplate;