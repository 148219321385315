import axios from "axios";
import React, { forwardRef, Fragment, useEffect, useState } from "react"
import { Button, Col, Form, Image, Row, Stack, Table } from "react-bootstrap";

import * as dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EntryListing from "./entryListing";

function AdvancedStats() {
    const [options, setOptions] = useState({});
    const [frequency, setFrequency] = useState(0);
    const [days, setDays] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    let [entries, setEntries] = useState([]);
    let [tableStats, setTableStats] = useState({});

    let [selectedCategory, setSelectedCategory] = useState([]);
    let [selectedOption, setSelectedOption] = useState([]);

    let [longestPeriod, setLongestPeriod] = useState(-1);
    let [longestPeriodWithout, setLongestPeriodWithout] = useState(-1);

    let [averageRating, setAverageRating] = useState(-1);
    let [averageRatingWithout, setAverageRatingWithout] = useState(-1);
    let [averageRatingPercentage, setAverageRatingPercentage] = useState(-1);

    let [moodRating, setMoodRating] = useState(0);
    let [symptomsEntries, setSymptomsEntries] = useState([]);

    let [symptomAverageSleepHours, setSymptomAverageSleepHours] = useState(0);

    let [symptomAverageSleepRating, setSymptomAverageSleepRating] = useState(0);
    const [customRatingOptions, setCustomRatingOptions] = useState({});

    const [tags, setTags] = useState({});

    const categories = {
        "feelings": "Feelings",
        "activities": "Activities",
        "social-interaction": "Social Interaction",
        "meals": "Meals",
        "medications": "Medications / Herbs / Supplements",
        "drugs": "Drugs / Alcohol",
        "symptoms": "Symptoms"
    };

    const oftenTogetherCategoryMapping = {
        "rating": ["feelings", "activities", "social-interaction", "meals", "medications", "drugs", "symptoms"],
        "feelings": ["activities", "social-interaction", "meals", "medications", "drugs"],
        "activities": ["rating", "feelings", "symptoms"],
        "social-interaction": ["rating", "feelings", "symptoms"],
        "meals": ["rating", "feelings", "symptoms"],
        "medications": ["rating", "feelings", "symptoms"],
        "drugs": ["rating", "feelings", "symptoms"],
        "symptoms": ["activities", "social-interaction", "meals", "medications", "drugs"],
        "custom": ["rating", "feelings", "symptoms"],
        "customRating": ["feelings", "activities", "social-interaction", "meals", "medications", "drugs", "symptoms"],
        "tag": ["rating", "feelings", "activities", "social-interaction", "meals", "medications", "drugs", "symptoms"]
    }

    useEffect(() => {
        getOptions();
        getCustomRatingOptions()
        loadCalendar(startDate);
    }, [])

    useEffect(() => {
        if (frequency > -1) {
            fetchOftenTogetherStats()
        }
    }, [frequency]);

    useEffect(() => {
        if (selectedCategory == "rating") {
            getRatingStats(startDate, selectedOption)
            getEntriesWithRating(startDate, selectedOption)
        } else if (customRatingOptions[selectedCategory]) {
            getEntriesWithCustomRating(startDate, selectedOption, customRatingOptions[selectedCategory].id)
        } else {
            getOptionStats(startDate)
            getEntries(startDate, selectedOption)
        }
        // fetchOftenTogetherStats()
    }, [startDate, selectedCategory, selectedOption]);

    function loadCalendar(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
        labels.map((key) => {
            days[key] = 0;
        });
        setDays(days)
    }

    const DateSwitcher = forwardRef(({ value, onClick }, ref) => (
        <Button style={{ marginLeft: '50px' }} onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    function getEntries(date, optionId, category) {
        // Reloading the calender.
        loadCalendar(date)
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=entry_option_stats&category=${selectedCategory}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}&optionId=${optionId}&join=activity_options,options&join=activity_tags,tags&join=journal`;
        axios.get(url).then((result) => {
            if (result.data.records) {
                const records = result.data.records
                const ids = []
                records.map((record)=> {
                    ids.push(record.id)
                });
                let url = url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=id,in,${ids.join(',')}&join=activity_options,options&join=activity_tags,tags&join=journal&join=habit_craving_level_activity&join=activity_custom_fields`;
                axios.get(url).then((result) => {
                    if (result.data.records) {
                        const utcOffset = dayjs().utcOffset() * 60
                        setEntries(result.data.records)
                        setFrequency(result.data.records.length)
                        const updatedDays = { ...days };
                        const ids = []
                        result.data.records.map((item) => {
                            const dayOfMonth = dayjs((item.added_on + utcOffset) * 1000).date()
                            updatedDays[dayOfMonth] = updatedDays[dayOfMonth] + 1;
                            ids.push(item.activity_id)
                        });
        
                        setDays({
                            ...days,
                            ...updatedDays
                        });
                        console.log("selectedCategoryselectedCategoryselectedCategory", selectedCategory)
                        if ((category || selectedCategory) == "symptoms") {
                            getEntriesBySymptomIds(ids)
                        }
                    }
                });
            }
        });
    }

    function getFeedEntries(date) {
        const startTime = dayjs(date).startOf('day').unix();
        const endTime = dayjs(date).endOf('day').unix();
        let url = ``;
        if (selectedCategory == "rating") {
            url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${startTime},${endTime}&filter=rating,eq,${selectedOption}&join=activity_options,options&join=activity_tags,tags&join=journal`;
        } else if (customRatingOptions[selectedCategory]) {
            url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=filter_records_custom_rating&start=${startTime}&end=${endTime}&optionId=${selectedOption}&rating=${customRatingOptions[selectedCategory].id}`;
        } else {
            url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=entry_option_stats&start=${startTime}&end=${endTime}&optionId=${selectedOption}&join=activity_options,options&join=activity_tags,tags&join=journal`;
        }
        axios.get(url).then((result) => {
            const records = result.data.records;
            if (records) {
                if (selectedCategory == "rating") {
                    setEntries(records)
                } else {
                    const ids = []
                    records.map((record)=> {
                        ids.push(record.id)
                    });
                    let url = url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=id,in,${ids.join(',')}&join=activity_options,options&join=activity_tags,tags&join=journal&join=habit_craving_level_activity&join=activity_custom_fields`;
                    axios.get(url).then((result) => {
                        setEntries(result.data.records)
                    });
                }
            }
        });
    }

    function getEntriesBySymptomIds(ids) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=id,in,${ids.join(',')}&join=activity_options,options&join=activity_tags,tags&join=habit_craving_level_activity&join=activity_custom_fields`;
        axios
            .get(url)
            .then((result) => {
                const filters = {};
                const records = result.data.records;
                const utcOffset = Math.abs(dayjs().utcOffset()) * 60
                console.log("dayjs().utcOffset()dayjs().utcOffset()", dayjs().utcOffset())
                if (records) {
                    setSymptomsEntries(records)
                    records.map((record, index) => {
                        const startDate = dayjs(record.added_on * 1000).subtract(1, 'day');
                        const startTime = startDate.startOf('day').unix() + utcOffset;
                        filters[startTime] = `filter${index + 1}=sleep_start_time,bt,${startTime},${startDate.endOf('day').unix() + utcOffset}`;
                    });
                    if (Object.keys(filters).length > 0) {
                        getSleepMetrics(Object.values(filters));
                    }
                }
            }, error => {
                alert(error);
            });
    }

    function getSleepMetrics(filters) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/activity_sleep?${filters.join("&")}`;
        axios
            .get(url)
            .then((result) => {
                const filters = [];
                const records = result.data.records;
                if (records) {
                    let averageSleepHours = 0;
                    let averageSleepRating = 0;
                    records.map((record) => {
                        averageSleepHours = averageSleepHours + (record.sleep_end_time - record.sleep_start_time);
                        averageSleepRating = averageSleepRating + record.sleep_rating;
                    });
                    setSymptomAverageSleepHours(averageSleepHours / records.length);
                    setSymptomAverageSleepRating(averageSleepRating / records.length);
                }
            }, error => {
                alert(error);
            });
    }

    function getEntriesWithRating(date, rating) {
        loadCalendar(date)
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}&filter=rating,eq,${rating}&join=activity_options,options&join=activity_tags,tags&join=journal`;
        axios.get(url).then((result) => {
            if (result.data.records.length) {
                const utcOffset = dayjs().utcOffset() * 60
                setEntries(result.data.records)
                setFrequency(result.data.records.length)
                const updatedDays = { ...days };
                result.data.records.map((item) => {
                    const dayOfMonth = dayjs((item.added_on + utcOffset) * 1000).date()
                    updatedDays[dayOfMonth] = updatedDays[dayOfMonth] + 1;
                });
                setDays({
                    ...days,
                    ...updatedDays
                });
            }
        });
    }

    function getEntriesWithCustomRating(date, rating, optionId) {
        loadCalendar(date)
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=filter_records_custom_rating&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}&optionId=${optionId}&rating=${rating}`;
        axios.get(url).then((result) => {
            if (result.data.records) {
                setEntries(result.data.records)
                setFrequency(result.data.records.length)
                const updatedDays = { ...days };
                const ids = []
                result.data.records.map((item) => {
                    const dayOfMonth = dayjs(item.added_on * 1000).date()
                    updatedDays[dayOfMonth] = updatedDays[dayOfMonth] + 1;
                    ids.push(item.activity_id)
                });

                setDays({
                    ...days,
                    ...updatedDays
                });
            }
        });
    }

    function getOptions() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options?join=custom_sections`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const options = {};
                records.map((item) => {
                    const category = item.category == "custom" ? item.custom_section_id.name : item.category
                    options[category] = options[category] || [];
                    options[category].push(item)
                });
                setOptions(options);
            }, error => {
                alert(error);
            });
    }

    function getCustomRatingOptions() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/custom_sections?filter=field_type,eq,rating`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                const options = {};
                records.map((item) => {
                    options[item.name] = item;
                });
                setCustomRatingOptions(options);
            }, error => {
                alert(error);
            });
    }

    function onOptionChange(value, target) {
        resetToDefaults()
        var category = target.options[target.selectedIndex].parentNode.id;
        setSelectedCategory(category)
        setSelectedOption(value)
    }

    function onTagClickHandler(tag) {
        resetToDefaults()
        setSelectedCategory("tag")
        setSelectedOption(tags[tag])
    }

    function spliceIntoChunks(daysAA, chunkSize) {
        const arr = Object.keys(daysAA);
        const res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }

        return res;
    }

    function getRatingStats(date, rating) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        let lastDayOfMonth = dayjs(date).endOf('month');

        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}`;

        if (lastDayOfMonth.unix() > dayjs(date).unix()) {
            lastDayOfMonth = dayjs(date)
        }
        axios.get(url).then((result) => {
            let updatedLongestPeriod = 0;
            let updatedLongestPeriodWithout = 0;
            const ratingByDays = {};
            if (result.data.records) {
                const averageRatingsByDays = []
                const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
                labels.map((key) => {
                    ratingByDays[key] = []
                });
                result.data.records.map((item) => {
                    const dayOfMonth = dayjs(item.added_on * 1000).date()
                    ratingByDays[dayOfMonth].push(item.rating);
                });
                let numberOfDays = 0
                let numberOfDaysWithout = 0
                Object.keys(ratingByDays).map(key => {
                    if (ratingByDays[key].includes(parseInt(rating))) {
                        numberOfDays = numberOfDays + 1
                        updatedLongestPeriodWithout = updatedLongestPeriodWithout < numberOfDaysWithout ? numberOfDaysWithout : updatedLongestPeriodWithout
                        numberOfDaysWithout = 0
                    } else {
                        updatedLongestPeriod = updatedLongestPeriod < numberOfDays ? numberOfDays : updatedLongestPeriod
                        numberOfDays = 0
                        numberOfDaysWithout++
                    }
                });
                // To handle edge case where all the days have same rating.
                updatedLongestPeriod = updatedLongestPeriod < numberOfDays ? numberOfDays : updatedLongestPeriod
                updatedLongestPeriodWithout = updatedLongestPeriodWithout < numberOfDaysWithout ? numberOfDaysWithout : updatedLongestPeriodWithout
            }
            setLongestPeriod(updatedLongestPeriod)
            setLongestPeriodWithout(updatedLongestPeriodWithout)

            averageRating = Math.round(averageRating)
            averageRatingWithout = Math.round(averageRatingWithout)
            // TODO: leaving the code around in case we need to add in future.            
            setAverageRating(-1)
            setAverageRatingWithout(-1)
            setAverageRatingPercentage(-1)
        });
    }

    function getOptionStats(date) {
        if (selectedOption.length == 0) {
            return
        }
        const firstDayOfMonth = dayjs(date).startOf('month');
        let lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}&join=activity_options`;
        if (lastDayOfMonth.unix() > dayjs(date).unix()) {
            lastDayOfMonth = dayjs(date)
        }
        axios.get(url).then((result) => {
            let updatedLongestPeriod = 0;
            let updatedLongestPeriodWithout = 0;
            let averageRating = 0
            let averageRatingWithout = 0
            const utcOffset = dayjs().utcOffset() * 60
            if (result.data.records) {
                const entriesByDays = []
                const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
                labels.map((key) => {
                    entriesByDays[key] = [];
                });
                const ratingEntries = []
                const ratingWithoutEntries = []
                result.data.records.map((item) => {
                    const dayOfMonth = dayjs((item.added_on + utcOffset) * 1000).date()
                    let optionIds = []
                    item.activity_options.map((option) => {
                        optionIds.push(option.option_id)
                    })
                    entriesByDays[dayOfMonth] = [...entriesByDays[dayOfMonth], ...optionIds]
                    
                    if (item.rating > 0) {
                        if (optionIds.includes(parseInt(selectedOption))) {
                            ratingEntries.push(item.rating)
                        } else {
                            ratingWithoutEntries.push(item.rating)
                        }
                    }
                });
                if (ratingEntries.length > 0) {
                    averageRating = ratingEntries.reduce((a, b) => a + b, 0) / ratingEntries.length
                }
                if (ratingWithoutEntries.length > 0) {
                    averageRatingWithout = ratingWithoutEntries.reduce((a, b) => a + b, 0) / ratingWithoutEntries.length
                }
                let numberOfDays = 0
                let numberOfDaysWithout = 0
                const entriesByDaysKeys = Object.keys(entriesByDays)
                console.log("entriesByDaysentriesByDays", entriesByDays, selectedOption)
                Object.values(entriesByDays).map((item, index) => {
                    if (item.includes(parseInt(selectedOption))) {
                        numberOfDays = numberOfDays + 1
                        updatedLongestPeriodWithout = updatedLongestPeriodWithout < numberOfDaysWithout ? numberOfDaysWithout : updatedLongestPeriodWithout
                        numberOfDaysWithout = 0
                    } else {
                        updatedLongestPeriod = updatedLongestPeriod < numberOfDays ? numberOfDays : updatedLongestPeriod
                        numberOfDays = 0
                        numberOfDaysWithout++
                    }
                });
                // To handle edge case where all the days have same rating.
                updatedLongestPeriod = updatedLongestPeriod < numberOfDays ? numberOfDays : updatedLongestPeriod
                updatedLongestPeriodWithout = updatedLongestPeriodWithout < numberOfDaysWithout ? numberOfDaysWithout : updatedLongestPeriodWithout
            }
            setLongestPeriod(updatedLongestPeriod)
            setLongestPeriodWithout(updatedLongestPeriodWithout)
            setAverageRating(averageRating.toFixed(1))
            setAverageRatingWithout(averageRatingWithout.toFixed(1))
            averageRating = Math.round(averageRating)
            averageRatingWithout = Math.round(averageRatingWithout)
            setAverageRatingPercentage(((averageRating / (averageRating + averageRatingWithout)) * 100).toFixed(1))
        });
    }


    function onDateChange(date) {
        resetToDefaults()
        setStartDate(date)
    }

    function resetToDefaults() {
        setMoodRating(-1)
        setSymptomsEntries([])
        setSymptomAverageSleepHours(0)
        setSymptomAverageSleepRating(0)
        setFrequency(-1)
        setLongestPeriod(-1)
        Object.keys(days).map((key) => {
            days[key] = 0;
        });
        setDays(days)
    }

    function fetchOftenTogetherStats() {
        if (selectedCategory.length == 0 || selectedOption.length == 0) {
            return
        }
        const firstDayOfMonth = dayjs(startDate).startOf('month');
        const lastDayOfMonth = dayjs(startDate).endOf('month');
        const updatedTagList = {}
        if (selectedCategory == "rating" || customRatingOptions[selectedCategory]) {
            const promises = []
            let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-rating&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[customRatingOptions[selectedCategory] ? 'customRating' : selectedCategory]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
            if (customRatingOptions[selectedCategory]) {
                url += `&customSectionId=${customRatingOptions[selectedCategory].id}`;
            }
            promises.push(axios.get(url))

            let tagStatsUrl = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-tags-ratings&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[customRatingOptions[selectedCategory] ? 'customRating' : selectedCategory]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
            if (customRatingOptions[selectedCategory]) {
                tagStatsUrl += `&customSectionId=${customRatingOptions[selectedCategory].id}`;
            }
            promises.push(axios.get(tagStatsUrl))

            if (customRatingOptions[selectedCategory]) {
                promises.push(axios.get(`${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-custom-rating&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${oftenTogetherCategoryMapping[customRatingOptions[selectedCategory] ? 'customRating' : selectedCategory]}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}&customSectionId=${customRatingOptions[selectedCategory].id}`))
            }
            Promise.all(promises).then((result) => {
                const updatedValue = {}
                if (result[0] && result[0].data.records) {
                    let stats = {}
                    result[0].data.records.map((record) => {
                        stats[record.name] = `${(parseFloat(record.count) / frequency) * 100}%`;
                    });
                    updatedValue['often-together-with-rating'] = {
                        'title': `Often Together with ${selectedCategory}`,
                        'data': stats
                    };
                }
                if (result[1] && result[1].data.records) {
                    let stats = {}
                    result[1].data.records.map((record) => {
                        stats[record.name] = `${(parseFloat(record.count) / frequency) * 100}%`;
                        updatedTagList[record.name] = record.id
                    });
                    updatedValue['often-together-with-rating-tag'] = {
                        'title': `Tags Often Together with ${selectedCategory}`,
                        'data': stats
                    };
                }
                if (result[2] && result[2].data.records) {
                    let stats = {}
                    result[2].data.records.map((record) => {
                        stats[record.rating] = `${(parseFloat(record.count) / frequency) * 100}%`;
                    });
                    updatedValue['often-together-with-custom-rating'] = {
                        'title': `Often Together with Rating`,
                        'data': stats
                    };
                }
                tableStats = {
                    ...tableStats,
                    ...updatedValue
                };
                setTags(updatedTagList)
                setTableStats(tableStats);
            });
        } else {
            const promises = []
            const requiredCategories = oftenTogetherCategoryMapping[selectedCategory] || oftenTogetherCategoryMapping["custom"];
            if (requiredCategories.indexOf("ratings")) {
                let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-rating-with-option&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${requiredCategories}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
                promises.push(axios.get(url))
            }
            let url = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${requiredCategories}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
            promises.push(axios.get(url))


            if (requiredCategories.indexOf("ratings")) {
                let tagsUrl = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-rating-with-tags&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${requiredCategories}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
                promises.push(axios.get(tagsUrl))
            }

            let tagsUrl = `${process.env.REACT_APP_API_URL}server/customApi.php?type=often-together-tags&category=${selectedCategory}&selectedOption=${selectedOption}&requiredCategories=${requiredCategories}&start=${firstDayOfMonth.unix()}&end=${lastDayOfMonth.unix()}`;
            promises.push(axios.get(tagsUrl))

            Promise.all(promises).then((result) => {
                const updatedValue = {}
                const ratingStats = {}
                let totalRatings = 0
                result[0].data.records.map((record) => {
                    totalRatings = totalRatings + (parseFloat(record.rating) * parseFloat(record.count));
                    ratingStats[record.rating] = `${(parseFloat(record.count) / frequency) * 100}%`;
                });
                updatedValue['often-together-with-rating'] = {
                    'title': "Related to Rating",
                    'data': ratingStats
                };
                const stats = {}
                if (result[1].data && result[1].data.records.length > 0) {
                    result[1].data.records.map((record) => {
                        stats[record.name] = `${(parseFloat(record.count) / frequency) * 100}%`;
                    });
                    updatedValue['often-together'] = {
                        'title': "Related",
                        'data': stats
                    };
                }

                // Stats for tags
                result[2].data.records.map((record) => {
                    totalRatings = totalRatings + (parseFloat(record.rating) * parseFloat(record.count));
                    ratingStats[record.rating] = `${(parseFloat(record.count) / frequency) * 100}%`;
                });
                updatedValue['often-together-with-rating-tag'] = {
                    'title': "Tags Related to Rating",
                    'data': ratingStats
                };
                const tagsStats = {}
                if (result[3].data && result[1].data.records.length > 0) {
                    result[3].data.records.map((record) => {
                        tagsStats[record.name] = `${(parseFloat(record.count) / frequency) * 100}%`;
                        updatedTagList[record.name] = record.name
                    });
                    updatedValue['often-together-tags'] = {
                        'title': "Tags Related",
                        'data': tagsStats
                    };
                }

                tableStats = {
                    ...tableStats,
                    ...updatedValue
                };
                setTableStats(tableStats);
                if (selectedCategory == "symptoms") {
                    setMoodRating(Math.ceil(totalRatings / frequency))
                }
                setTags(updatedTagList)
            })
        }
    }

    function refreshEntries(day) {
        getFeedEntries(dayjs(startDate).startOf('month').add(day - 1, 'day'))
    }


    return (
        <Stack>
            <Stack direction="horizontal" gap={5} className="p-5">
                <Button variant="secondary" onClick={() => { const date = dayjs(startDate).subtract(1, 'month'); onDateChange(date); }}>Prev</Button>
                {dayjs(startDate).format('MMM')}
                <Button disabled={dayjs(startDate).month() == dayjs().month()} variant="secondary" onClick={() => { const date = dayjs(startDate).add(1, 'month'); onDateChange(date); }}>Next</Button>
            </Stack>
            <Stack direction="horizontal" gap={2}>
                <Form.Select onChange={(e) => { onOptionChange(e.target.value, e.target) }} className="mx-5" style={{ width: "300px" }}>
                    <optgroup label="">
                        <option value="0">---Select--</option>
                    </optgroup>
                    <optgroup id="rating" label="Rating">
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                        <option value="4">Four</option>
                        <option value="5">Five</option>
                    </optgroup>
                    {Object.keys(options).map(category =>
                        <optgroup key={category} id={category} label={`${categories[category] || category}`}>
                            {options[category] && options[category].map(option =>
                                <option key={option.id} value={`${option.id}`}>{option.name}</option>
                            )}
                        </optgroup>
                    )}
                    {Object.keys(customRatingOptions).map((key) =>
                        <optgroup id={key} label={key}>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                            <option value="4">Four</option>
                            <option value="5">Five</option>
                        </optgroup>
                    )}
                </Form.Select>
            </Stack>
            <Stack>
                <Table striped bordered hover style={{ maxWidth: '400px' }} className="m-5">
                    <tbody>
                        <tr>
                            <td>Frequency</td><td>{frequency > -1 ? frequency : 0}</td>
                        </tr>
                        {longestPeriod > -1 &&
                            <tr><td>Longest Period with Selected Option</td><td>{longestPeriod}</td></tr>
                        }
                        {longestPeriod > -1 &&
                            <tr><td>Longest Period without Selected Option </td><td>{longestPeriodWithout}</td></tr>
                        }
                        {moodRating > 0 &&
                            <tr><td>Mood Rating</td><td>{moodRating}</td></tr>
                        }
                        {symptomAverageSleepHours > 0 &&
                            <tr><td>Average hrs sleep night before</td><td>{Math.round(symptomAverageSleepHours / 3600)} hrs</td></tr>
                        }
                        {symptomAverageSleepRating > 0 &&
                            <tr><td>Average sleep quality night before</td><td>{symptomAverageSleepRating}</td></tr>
                        }

                        {averageRating > -1 &&
                            <tr><td>Average Rating with Selected Option</td><td>{averageRating}</td></tr>
                        }
                        {averageRatingWithout > -1 &&
                            <tr><td>Average Rating without Selected Option</td><td>{averageRatingWithout}</td></tr>
                        }
                        {averageRatingPercentage > -1 &&
                            <tr><td>Average Rating Percentage</td><td>{Math.abs(averageRatingPercentage)}% {averageRating > averageRatingWithout ? "Increase" : "Decrease"}</td></tr>
                        }

                    </tbody>
                </Table>
                <h1>Occurrences</h1>
                <Table bordered className="m-5">
                    <tbody>
                        {days && spliceIntoChunks(days, 7).map((result, key) =>
                            <tr key={key}>
                                {result.map((item) =>
                                    <td key={item} onClick={() => { refreshEntries(item) }} style={{ cursor: 'pointer', backgroundColor: days[item] > 0 ? '#FFF000' : '' }}>{item}</td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Stack>
            {Object.keys(tableStats).map((category) =>
                <Stack key={category}>
                    <h5>{tableStats[category].title}</h5>
                    <Table striped bordered hover style={{ maxWidth: '400px' }}>
                        <tbody>
                            {Object.keys(tableStats[category].data).map((key) =>
                                <tr key={key}>
                                    {tags[key] &&
                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => onTagClickHandler(key)}>{key}</td>
                                    }
                                    {!category.includes('-tags') &&
                                        <td>{key}</td>
                                    }
                                    <td>{tableStats[category].data[key]}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Stack>
            )}
            <EntryListing
                entries={symptomsEntries}
            />
            <EntryListing entries={entries} className="m-5" />
        </Stack>
    );
}

export default AdvancedStats;