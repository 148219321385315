import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

function AddEditGoalCategory() {
    const [displayToast, enableToastDisplay] = useState(false);
    const [name, setName] = useState("")

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            getGoalCategoryInfo(id)
        }
    }, []);

    function getGoalCategoryInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_categories/${id}`;
        axios
            .get(url)
            .then((result) => {
                const option = result.data
                setName(option.name)
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_categories${(id != null ? `/${id}` : '')}`;
        const data = {
            name: name
        };
        let promise = null
        if (id) {
            promise = axios.put(url, data, {});
        } else {
            promise = axios.post(url, data, {})
        }
        promise.then((res) => {
            enableToastDisplay(true);
        }, error => {
            alert(error);
        });
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Changes Saved successfully.</strong>
                    </Toast.Header>
                </Toast>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={name} onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>


                    <Stack direction='horizontal' gap={3}>
                        <Button variant="primary" onClick={onSubmitHandler}>
                            Submit
                        </Button>
                    </Stack>
                </Form>
            </Container>
        </div>
    );
}

export default AddEditGoalCategory;