import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import React, { Component }  from 'react';
import { useNavigate } from "react-router-dom"

function ManageGoalCategories() {
    const navigate = useNavigate()
    const [goalCategories, setGoalCategories] = useState([]);
    
    useEffect(() => {
        getGoalCategoryList()
    }, []);

    function getGoalCategoryList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_categories`;
        axios
            .get(url)
            .then((result) => {
                setGoalCategories(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function deleteGoalCategory(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/goal_categories/${id}`;
        axios
            .delete(url)
            .then((res) => {
                getGoalCategoryList()
            }, error => {
                alert(error);
            });
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Button onClick={() => { navigate(`/goal-category`) }}>Create Goal Category</Button>
                <Stack gap={2}>
                    {goalCategories && goalCategories.map((item) =>
                        <Stack key={item.id} gap={2} direction="horizontal">
                            <strong className="me-auto">{item.name}</strong>
                            <Button variant="danger" onClick={() => { deleteGoalCategory(item.id) }}>Delete</Button>
                            <Button variant="primary" onClick={() => { navigate(`/goal-category/${item.id}`) }}>Edit</Button>
                        </Stack>
                    )}
                </Stack>
            </Container>
        </div>
    );
}

export default ManageGoalCategories;