import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ReactDatePicker from "react-datepicker";
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import * as dayjs from "dayjs";

function AddEditReminder() {
    const [displayToast, enableToastDisplay] = useState(false);
    const [time, setTime] = useState("")

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            getReminderInfo(id)
        }
    }, []);

    function getReminderInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders/${id}`;
        axios
            .get(url)
            .then((result) => {
                const option = result.data
                setTime(option.time * 1000)
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders${(id != null ? `/${id}` : '')}`;
        const data = {
            time: dayjs(time).unix()
        };
        if (id) {
            axios
                .put(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        } else {
            axios
                .post(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        }
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Changes Saved successfully.</strong>
                    </Toast.Header>
                </Toast>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Time</Form.Label>
                        <ReactDatePicker
                            selected={time}
                            onChange={(date) => setTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="h:mm aa"
                        />
                    </Form.Group>

                    <Stack direction='horizontal' gap={3}>
                        <Button variant="primary" onClick={onSubmitHandler}>
                            Submit
                        </Button>
                    </Stack>
                </Form>
            </Container>
        </div>
    );
}

export default AddEditReminder;