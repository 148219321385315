import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import React, { Component }  from 'react';
import { useNavigate } from "react-router-dom"

function ManageIcons() {
    const navigate = useNavigate()
    const [displayToast, enableToastDisplay] = useState(false);
    const [iconList, setIconList] = useState([]);
    
    useEffect(() => {
        getIconList()
    }, []);

    function getIconList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/icons`;
        axios
            .get(url)
            .then((result) => {
                setIconList(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function deleteIcon(id) {
        axios.get(`${process.env.REACT_APP_API_URL}server/customApi.php?type=delete_icon&id=${id}`)
            .then((res) => {
                getIconList()
            }, error => {
                alert(error);
            });
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Icon upload Success.</strong>
                    </Toast.Header>
                </Toast>
                <Stack gap={2}>
                    {iconList && iconList.map((item) =>
                        <Stack key={item.id} gap={2} direction="horizontal">
                            <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + item.path} />
                            <Button variant="danger" onClick={() => { navigate(`/icon/${item.id}`) }}>Edit</Button>
                            <Button variant="danger" onClick={() => { deleteIcon(item.id) }}>Delete</Button>
                        </Stack>
                    )}
                </Stack>
            </Container>
        </div>
    );
}

export default ManageIcons;