import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import IconList from './iconList';

function AddDefaultOption() {
    const [displayToast, enableToastDisplay] = useState(false);
    const [optionType, setOptionType] = useState("feelings");
    const [uploadedFile, setUploadedFile] = useState("");
    const [name, setName] = useState("")
    const [emojiUniCode, setEmojiUniCode] = useState("")
    const [emotionCategory, setEmotionCategory] = useState(0)

    const categories = {
        "feelings": "Feelings",
        "activities": "Activities",
        "social-interaction": "Social Interaction",
        "meals": "Meals",
        "medications": "Medications / Herbs / Supplements",
        "drugs": "Drugs / Alcohol",
        "symptoms": "Symptoms",
        "tags": "Tags",
        "supplements": "Supplements"
    };

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            getOptionInfo(id)
        }
    }, []);

    function getOptionInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options/${id}`;
        axios
            .get(url)
            .then((result) => {
                const option = result.data
                setEmojiUniCode(option.emojiUniCode)
                setUploadedFile(option.iconImage)
                setOptionType(option.category)
                setName(option.name)
                setEmotionCategory(option.emotionCategory)
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options${(id != null ? `/${id}` : '')}`;
        const data = {
            name: name,
            category: optionType,
            iconImage: uploadedFile,
            emojiUniCode: emojiUniCode,
            emotionCategory: emotionCategory
        };
        if (id) {
            axios
                .put(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        } else {
            axios
                .post(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        }
    }

    function onNameChange(event) {
        setName(event.target.value)
    }

    function onEmojiUnicodeChange(event) {
        setEmojiUniCode(event.target.value)
    }

    function onOptionTypeChange(event) {
        setOptionType(event.target.value);
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Add option Success.</strong>
                    </Toast.Header>
                </Toast>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Option type</Form.Label>
                        <Form.Select aria-label="" onChange={onOptionTypeChange} value={optionType}>
                            {Object.keys(categories).map((key) => {
                                return (
                                    <option key={key} value={key}>{categories[key]}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    {optionType == "feelings" &&
                        <Form.Group className="mb-3">
                            <Form.Label>Emotion Category</Form.Label>
                            <Form.Select aria-label="" onChange={(e) => setEmotionCategory(e.target.value) } value={emotionCategory}>
                                <option key="emo-cat-0" value="0">-- Select --</option>
                                <option key="emo-cat-1" value="1">Positive + high</option>
                                <option key="emo-cat-2" value="2">Positive + low</option>
                                <option key="emo-cat-3" value="3">Negative + low</option>
                                <option key="emo-cat-4" value="4">Negative + high</option>
                            </Form.Select>
                        </Form.Group>                    
                    }
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={name} onChange={onNameChange} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Emoji unicode</Form.Label>
                        <Form.Control type="text" placeholder="Enter emoji unicode" value={emojiUniCode} onChange={onEmojiUnicodeChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Icon Image</Form.Label>
                        <FileUploader onCompletionHandler={(files) => { setUploadedFile(files[0].path) }} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {uploadedFile && uploadedFile.length > 0 &&
                            <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + uploadedFile} />
                        }
                    </Form.Group>
                    <Button variant="primary" onClick={onSubmitHandler}>
                        Submit
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

export default AddDefaultOption;