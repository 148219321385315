import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, FloatingLabel, Image, Stack, Table, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React, { Component } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import * as dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import IconList from './iconList';
import EntryListing from '../user/entryListing';

function AddEditHabit(props) {
    const navigate = useNavigate()
    const [displayToast, enableToastDisplay] = useState(false);
    const [selectedIconId, setSelectedIconId] = useState("")
    const [uploadedFile, setUploadedFile] = useState("");
    const [fullWidthImage, setFullWidthImage] = useState("");
    const [name, setName] = useState("")
    const [enableReminder, setEnableReminder] = useState(false)
    const [reminderTime, setReminderTime] = useState(new Date())
    const [reminderId, setReminderId] = useState(null)
    const [habitSuccess, setHabitSuccess] = useState(true)
    const [cravingLevel, setCravingLevel] = useState(0)
    const [tags, setTags] = useState("")
    const [notes, setNotes] = useState("")
    const [habitEntries, setHabitEntries] = useState([]);
    const [failedHabitEntries, setFailedHabitEntries] = useState([]);
    const [currentDayFailedHabitEntry, setCurrentDayFailedHabitEntry] = useState({});
    const [longestStreak, setLongestStreak] = useState(0)
    const [currentStreak, setCurrentStreak] = useState(0)
    let [entries, setEntries] = useState([]);
    const [relatedFailsTagsCount, setRelatedFailsTagsCount] = useState({})
    const [relatedLevelsTagsCount, setRelatedLevelsTagsCount] = useState({})
    const [relatedLevelsOptionsCount, setRelatedLevelsOptionsCount] = useState({})
    const [failedEntriesOptionsCount, setFailedEntriesOptionsCount] = useState({})
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [limitType, setLimitType] = useState("");

    let [calender, setCalender] = useState({});

    let [isDefault, setIsDefault] = useState(props.isDefault || false);

    const { id } = useParams()
    const { date } = useParams()

    useEffect(() => {
        if (id) {
            getHabitInfo(id)
            getFailedHabitStatusEntries()
            getReminderInfo(id)
        }
        getCategoryList()
    }, []);


    function getCalendarDays() {
        let selectedDate = dayjs(date || new Date())
        const firstDayOfMonth = selectedDate.startOf('month');
        const lastDayOfMonth = selectedDate.endOf('month');
        const labels = Array.from({ length: lastDayOfMonth.date() }, (_, i) => i + 1);
        const items = {}
        labels.map((key) => {
            items[key] = 0;
        });

        return items
    }

    function getHabitInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habits/${id}`;
        axios
            .get(url)
            .then((result) => {
                const option = result.data
                setSelectedIconId(option.icon_id)
                setName(option.name)
                setIsDefault(option.isDefault)
                setUploadedFile(option.iconImage)
            }, error => {
                alert(error);
            });
    }

    function getCategoryList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_categories`;
        axios
            .get(url)
            .then((result) => {
                setCategories(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habits${(id != null ? `/${id}` : '')}`;
        let data = {
            name: name,
            isDefault: isDefault,
            category: category,
            limitHabitType: limitType
        };
        if (selectedIconId && selectedIconId.length > 0) {
            data["icon_id"] = selectedIconId
        }
        if (uploadedFile && uploadedFile.length > 0) {
            data["iconImage"] = uploadedFile
        }
        if (fullWidthImage && fullWidthImage.length > 0) {
            data["fullWidthImage"] = fullWidthImage
        }
        if (limitType && limitType.length > 0) {
            data["limitHabitType"] = limitType
        }
        if (id) {
            axios
                .put(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        } else {
            data["is_active"] = !isDefault
            axios
                .post(url, data, {})
                .then((res) => {
                    enableToastDisplay(true);
                }, error => {
                    alert(error);
                });
        }
    }

    function createReminder() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders${reminderId ? `/${reminderId}` : ''}`;
        const data = {
            time: dayjs(reminderTime).unix(),
            type: 'habit',
            type_id: id
        };
        const promise = reminderId ? axios.put(url, data, {}) : axios.post(url, data, {});
        promise.then((res) => {
            enableToastDisplay(true);
        }, error => {
            alert(error);
        });
    }

    function getReminderInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/reminders?filter=type,eq,habit&filter=type_id,eq,${id}`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                if (records && records.length > 0) {
                    setEnableReminder(true);
                    setReminderTime(records[0].time * 1000);
                    setReminderId(records[0].id)
                }
            }, error => {
                alert(error);
            });
    }

    function addHabitFailEntry() {
        if (!habitSuccess) {
            let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity${currentDayFailedHabitEntry.id ? `/${currentDayFailedHabitEntry.id}` : ''}`;
            const data = {
                // craving_level: cravingLevel,
                tags: tags,
                notes: notes,
                added_on: dayjs(date || new Date()).unix(),
                habit_id: id
            };
            const promise = currentDayFailedHabitEntry.id ? axios.put(url, data, {}) : axios.post(url, data, {})

            promise.then((res) => {
                enableToastDisplay(true);
            }, error => {
                alert(error);
            });
        }
    }

    function deleteFailEntry() {
        if (currentDayFailedHabitEntry.id) {
            let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity/${currentDayFailedHabitEntry.id}`;
            axios.delete(url).then((res) => {
                getFailedHabitStatusEntries()
            }, error => {
                alert(error);
            });
        }
    }

    function getHabitStatusByCravingLevel(rating) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_craving_level_activity?filter=craving_level,eq,${rating}&filter=habit_id,eq,${id}&join=record_entry&join=record_entry,activity_options,options&join=record_entry,activity_tags,tags`;
        axios
            .get(url)
            .then((result) => {
                const relatedLevelsTagsCount = {}
                const relatedLevelsOptionsCount = {}
                const records = result.data.records
                setHabitEntries(records);
                records.map((record) => {
                    if (record.activity_id.activity_options) {
                        record.activity_id.activity_options.map((option) => {
                            const name = option.option_id.name;
                            relatedLevelsOptionsCount[name] = relatedLevelsOptionsCount[name] ? relatedLevelsOptionsCount[name] + 1 : 1;
                        })
                    }

                    if (record.activity_id.activity_tags) {
                        record.activity_id.activity_tags.map((tag) => {
                            const name = tag.tag_id.name;
                            relatedLevelsTagsCount[name] = relatedLevelsTagsCount[name] ? relatedLevelsTagsCount[name] + 1 : 1;
                        })
                    }
                });
                console.log("relatedLevelsTagsCountrelatedLevelsTagsCount", relatedLevelsTagsCount)
                setRelatedLevelsTagsCount(relatedLevelsTagsCount)
                setRelatedLevelsOptionsCount(relatedLevelsOptionsCount)
            }, error => {
                alert(error);
            });
    }

    function getFailedHabitStatusEntries() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/habit_fail_activity?filter=habit_id,eq,${id}&order=added_on,desc&join=record_entry&join=record_entry,activity_options,options`;
        axios
            .get(url)
            .then((result) => {
                const records = result.data.records;
                let failedDays = [];
                setFailedHabitEntries(records);
                const items = getCalendarDays();
                const relatedFailsTagsCount = {}
                if (records) {
                    records.map((record) => {
                        let selectedDate = dayjs(date || new Date())
                        const startOfDay = selectedDate.startOf('day').unix()
                        const endOfDay = selectedDate.endOf('day').unix()
                        const addedOn = record.added_on;
                        relatedFailsTagsCount[record.tags] = relatedFailsTagsCount[record.tags] ? relatedFailsTagsCount[record.tags] + 1 : 1
                        if (addedOn >= startOfDay && addedOn < endOfDay) {
                            setCurrentDayFailedHabitEntry(record)
                            setHabitSuccess(false)
                            setNotes(record.notes)
                            setTags(record.tags)
                            failedDays.push(dayjs(addedOn * 1000).startOf('day').unix())
                        }
                        items[dayjs(addedOn * 1000).date()] = 1
                        if (!date) {
                            failedDays.push(dayjs(record.added_on * 1000).startOf('day').unix())
                        }
                    })
                    // Creating unique set of days.
                    failedDays = failedDays.filter((x, i, a) => a.indexOf(x) == i)
                    getEntries(failedDays);
                    calculateMetrics(records);
                    setCalender(items)
                    setRelatedFailsTagsCount(relatedFailsTagsCount)
                }
            }, error => {
                alert(error);
            });
    }

    function getEntries(failedDays) {
        const filters = []
        failedDays.map((day, index) => {
            filters.push(`filter${index + 1}=added_on,bt,${day},${dayjs(day * 1000).endOf('day').unix()}`)
        })
        if (filters.length == 0) {
            setFailedEntriesOptionsCount({})
            return
        }
        const failedEntriesOptionsCount = {}
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?${filters.join("&")}&join=activity_options,options&join=activity_tags,tags&join=habit_craving_level_activity&join=activity_custom_fields`;
        axios.get(url).then((result) => {
            if (result.data.records) {
                const records = result.data.records
                setEntries(records)
                records.map((record) => {
                    if (record.activity_options) {
                        record.activity_options.map((option) => {
                            const name = option.option_id.name;
                            failedEntriesOptionsCount[name] = failedEntriesOptionsCount[name] ? failedEntriesOptionsCount[name] + 1 : 1;
                        })
                    }
                });
                setFailedEntriesOptionsCount(failedEntriesOptionsCount)
            }
        });
    }

    function calculateMetrics(records) {
        let updatedLongestStreak = 0
        let currentLongestStreak = 0
        let streak = 0
        let failedEntries = [...records]
        failedEntries = failedEntries.reverse()
        var previousAddedOn = null;
        failedEntries.map((record) => {
            if (previousAddedOn == null || dayjs(record.added_on * 1000).diff(dayjs(previousAddedOn * 1000), 'day') <= 1) {
                previousAddedOn = record.added_on
                streak++
            } else {
                updatedLongestStreak = updatedLongestStreak < streak ? streak : updatedLongestStreak
                currentLongestStreak = streak
                streak = 1
            }
        })
        currentLongestStreak = streak > 0 ? streak : currentLongestStreak;
        updatedLongestStreak = updatedLongestStreak < streak ? streak : updatedLongestStreak
        setLongestStreak(updatedLongestStreak)
        setCurrentStreak(currentLongestStreak)
    }

    function spliceIntoChunks(daysAA, chunkSize) {
        const arr = Object.keys(daysAA);
        const res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }

        return res;
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Changes Saved successfully.</strong>
                    </Toast.Header>
                </Toast>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={name} onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Limit Habit Type</Form.Label>
                        <Form.Select aria-label="" onChange={(event) => { const limitType = event.target.value; setLimitType(limitType); }} value={limitType}>
                            <option key="" value="">--Select--</option>
                            {["consume", "activity", "spending"].map((limitType) => {
                                return (
                                    <option key={limitType} value={limitType}>{limitType}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Category</Form.Label>
                        <Form.Select aria-label="" onChange={(event) => { const category = event.target.value; setCategory(category); }} value={category}>
                            <option key="" value="">--Select--</option>
                            {categories.map((category) => {
                                return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Check
                        checked={enableReminder}
                        onChange={(event) => setEnableReminder(event.target.checked)}
                        type="switch"
                        id="custom-switch"
                        label="Enable Reminders"
                    />
                    {enableReminder &&
                        <Stack direction='horizontal' gap={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Time</Form.Label>
                                <ReactDatePicker
                                    selected={reminderTime}
                                    onChange={(date) => setReminderTime(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    dateFormat="h:mm aa"
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={createReminder}>
                                Set Reminder
                            </Button>
                        </Stack>
                    }

                    {id &&
                        <>
                            <Stack className='mb-2 mt-2' direction='horizontal' gap={3}>
                                <Button variant="success" onClick={() => { setHabitSuccess(true); deleteFailEntry() }}>
                                    Success
                                </Button>
                                <Button variant="danger" onClick={() => { setHabitSuccess(false) }}>
                                    Fail
                                </Button>
                            </Stack>
                            {!habitSuccess &&
                                <>
                                    <FloatingLabel label="Notes" className="mb-3">
                                        <Form.Control as="textarea" value={notes} placeholder="Leave a note here" onChange={(evt) => { setNotes(evt.target.value) }} />
                                    </FloatingLabel>
                                    <FloatingLabel label="Tags" className="mb-3">
                                        <Form.Control as="textarea" value={tags} placeholder="Tags here" onChange={(evt) => { setTags(evt.target.value) }} />
                                    </FloatingLabel>
                                    <Button onClick={addHabitFailEntry}>
                                        Save
                                    </Button>
                                </>
                            }
                        </>
                    }
                    {!isDefault &&
                        <IconList selectedIconId={selectedIconId} onSelectedIconChange={(iconId) => { setSelectedIconId(iconId) }} />
                    }
                    {isDefault &&
                        <>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Icon Image</Form.Label>
                                <FileUploader onCompletionHandler={(files) => { setUploadedFile(files[0].path) }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {uploadedFile && uploadedFile.length > 0 &&
                                    <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + uploadedFile} />
                                }
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Full width Image</Form.Label>
                                <FileUploader onCompletionHandler={(files) => { setFullWidthImage(files[0].path) }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {fullWidthImage && fullWidthImage.length > 0 &&
                                    <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + fullWidthImage} />
                                }
                            </Form.Group>
                        </>
                    }
                    
                    <Stack direction='horizontal' gap={3} className="mt-5">
                        <Button variant="primary" onClick={onSubmitHandler}>
                            Submit
                        </Button>
                    </Stack>
                    <Stack>
                        <>
                            <Stack className='mt-5 mb-5'>
                                <h6>Longest Streak: {longestStreak}</h6>
                                <h6>Current Streak: {currentStreak}</h6>
                            </Stack>
                            <h4>Related (Fails)</h4>
                            {failedHabitEntries && failedHabitEntries.map((item) =>
                                <Stack key={item.id} gap={2} direction="horizontal">
                                    <span style={{ minWidth: '300px' }}>{item.notes}</span>
                                    <span style={{ minWidth: '200px' }}>{item.tags}</span>
                                    <Stack gap={1} direction="horizontal">
                                        {item.activity_id && item.activity_id.activity_options && item.activity_id.activity_options.map((option, index) =>
                                            <div key={option.option_id.id} className="bg-light border p-3" style={{ width: '100px' }}>{option.option_id.name} <span style={{ fontWeight: 'bold' }}>{option.rating ? ` with rating - ${option.rating}` : ""}</span></div>
                                        )}
                                    </Stack>
                                </Stack>
                            )}

                            {Object.keys(failedEntriesOptionsCount).length > 0 &&
                                <Stack gap={2}>
                                    <h4>Related (Fails) Options Count</h4>
                                    {Object.keys(failedEntriesOptionsCount).map((option) =>
                                        <Stack key={option} gap={2} direction="horizontal">
                                            <span style={{ minWidth: '300px' }}>{option}</span>
                                            <span style={{ minWidth: '200px' }}>{failedEntriesOptionsCount[option]}</span>
                                        </Stack>
                                    )}
                                </Stack>
                            }

                            <h4>Related (Fails) Tags Count</h4>
                            <Stack gap={2}>
                                {relatedFailsTagsCount && Object.keys(relatedFailsTagsCount).map((tag) =>
                                    <Stack key={tag} gap={2} direction="horizontal">
                                        <span style={{ minWidth: '300px' }}>{tag}</span>
                                        <span style={{ minWidth: '200px' }}>{relatedFailsTagsCount[tag]}</span>
                                    </Stack>
                                )}
                            </Stack>
                            <h4 className="mt-3">Related (Levels)</h4>
                            <Form.Group>
                                <Form.Label>Levels</Form.Label>
                                <Form.Select aria-label="" onChange={(event) => { getHabitStatusByCravingLevel(event.target.value) }}>
                                    <option key="-1" value="">--select--</option>
                                    <option key="1" value="1">1</option>
                                    <option key="2" value="2">2</option>
                                    <option key="3" value="3">3</option>
                                    <option key="4" value="4">4</option>
                                    <option key="5" value="5">5</option>
                                </Form.Select>
                            </Form.Group>
                            {habitEntries && habitEntries.map((item) =>
                                <Stack key={item.id} gap={2} direction="horizontal">
                                    <span className="me-auto">{item.activity_id.notes}</span>
                                    <span className="me-auto">{item.tags}</span>
                                    <Stack direction="horizontal">
                                        {item.activity_id && item.activity_id.activity_tags && item.activity_id.activity_tags.map((tag, index) =>
                                            <div key={tag.tag_id.id} style={{ width: '100px' }}>{tag.tag_id.name}, </div>
                                        )}
                                    </Stack>
                                    <Stack gap={1} direction="horizontal">
                                        {item.activity_id && item.activity_id.activity_options && item.activity_id.activity_options.map((option, index) =>
                                            <div key={option.option_id.id} className="bg-light border p-3" style={{ width: '100px' }}>{option.option_id.name} <span style={{ fontWeight: 'bold' }}>{option.rating ? ` with rating - ${option.rating}` : ""}</span></div>
                                        )}
                                    </Stack>
                                </Stack>
                            )}
                            {Object.keys(relatedLevelsTagsCount).length > 0 &&
                                <Stack gap={2}>
                                    <h4>Related (Levels) Tags Count</h4>
                                    {Object.keys(relatedLevelsTagsCount).map((tag) =>
                                        <Stack key={tag} gap={2} direction="horizontal">
                                            <span style={{ minWidth: '300px' }}>{tag}</span>
                                            <span style={{ minWidth: '200px' }}>{relatedLevelsTagsCount[tag]}</span>
                                        </Stack>
                                    )}
                                </Stack>
                            }
                            {Object.keys(relatedLevelsOptionsCount).length > 0 &&
                                <Stack gap={2}>
                                    <h4>Related (Levels) Options Count</h4>
                                    {Object.keys(relatedLevelsOptionsCount).map((option) =>
                                        <Stack key={option} gap={2} direction="horizontal">
                                            <span style={{ minWidth: '300px' }}>{option}</span>
                                            <span style={{ minWidth: '200px' }}>{relatedLevelsOptionsCount[option]}</span>
                                        </Stack>
                                    )}
                                </Stack>
                            }
                        </>
                    </Stack>
                </Form>
                <EntryListing
                    entries={entries}
                />
                <Table striped bordered className="m-5">
                    <tbody>
                        {calender && spliceIntoChunks(calender, 7).map((result, key) =>
                            <tr key={key}>
                                {result.map((item) =>
                                    <td key={item} onClick={() => { window.location.href = `${window.location.origin}/habit/${id}/${dayjs(date || new Date()).startOf('month').add(item - 1, 'day').format('YYYY-MM-DD')}` }} style={{ cursor: "pointer", backgroundColor: calender[item] > 0 ? '#FF0000' : '' }}>{item}</td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    );
}

export default AddEditHabit;