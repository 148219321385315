import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

function IconUpload(props) {
    let { id } = useParams();
    const [icon, setIcon] = useState({});
    const [tag, setTag] = useState("");
    const [displayToast, enableToastDisplay] = useState(false);
    const [uploadedFile, setUploadedFile] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [iconCategories, setIconCategories] = useState([]);

    useEffect(() => {
        if (id) {
            getIconInfo(id)
        }
        getIconCategoryList()
    }, []);

    function getIconInfo(id) {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/icons/${id}`;
        axios
            .get(url)
            .then((result) => {
                const icon = result.data
                setIcon(icon)
                setSelectedCategory(icon.category_id)
                setUploadedFile(icon.path)
                setTag(icon.tags)
            }, error => {
                alert(error);
            });
    }

    function getIconCategoryList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/icon_categories`;
        axios
            .get(url)
            .then((result) => {
                setIconCategories(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        const data = {
            path: uploadedFile,
            category_id: selectedCategory,
            tags: tag
        };

        let url = `${process.env.REACT_APP_API_URL}index.php/records/icons${(id != null ? `/${id}` : '')}`
        let promise = id != null ? axios.put(url, data, {}) : axios.post(url, data, {})

        promise.then((res) => {
            enableToastDisplay(true);
        }, error => {
            alert(error);
        });

    }

    function onTagValueChange(tag) {
        setTag(tag)
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Icon upload Success.</strong>
                    </Toast.Header>
                </Toast>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Icon Image</Form.Label>
                        <FileUploader onCompletionHandler={(files) => { setUploadedFile(files[0].path) }} />
                    </Form.Group>
                    {uploadedFile.length > 0 &&
                        <Image thumbnail={true} width="100" src={process.env.REACT_APP_API_URL + uploadedFile} />
                    }
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Category</Form.Label>
                        <Form.Select aria-label="" value={icon.category_id} onChange={(evt) => setSelectedCategory(evt.target.value)}>
                            <option key="" value="">Select Category</option>
                            {iconCategories.map((category) => {
                                return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Tag</Form.Label>
                        <Form.Control type="text" placeholder="Enter tag" onChange={(evt) => { onTagValueChange(evt.target.value) }} value={tag} />
                        <Form.Text className="text-muted">
                            Add comma seperated tags for multiple options.
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" onClick={onSubmitHandler}>
                        Submit
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

export default IconUpload;