import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Nav, Stack, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom"

function More() {
    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Nav>
                    <Nav.Item>
                        <Nav.Link href="/manage-goals">Goals</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="reminders" href="/reminders">Reminders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="templates" href="/templates">Manage templates</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="templates" href="/user-default-templates">User Default templates</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="templates" href="/default-template">Add Default template</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="templates" href="/habits">break a habit</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="icon-categories" href="/icon-categories">Icon Categories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="goal-categories" href="/goal-categories">Goal Categories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="habit-categories" href="/habit-categories">Habit Categories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="manage-default-goals" href="/manage-default-goals">Manage Default Goals</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Container>
        </div>
    );
}

export default More