import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';
import axios from "axios";
import { Container, Image, Stack, Toast } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom"

function ManageOptions(props) {
    const navigate = useNavigate()
    const [displayToast, enableToastDisplay] = useState(false);
    const [optionList, setOptionList] = useState({});

    useEffect(() => {
        getOptionList()
    }, []);

    function getOptionList() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options?filter1=type,eq,${props.type || "default"}&join=custom_sections`;
        if (props.type == "user") {
            url = url + `&filter2=type,eq,custom`;
        }
        axios
            .get(url)
            .then((result) => {
                const list = {}
                result.data.records.map((item) => {
                    const category = item.custom_section_id ? item.custom_section_id.name : item.category;
                    list[category] = list[category] || []
                    list[category].push(item)
                });
                console.log(list);
                setOptionList(list);
            }, error => {
                alert(error);
            });
    }

    function deleteOption(id) {
        axios.get(`${process.env.REACT_APP_API_URL}server/customApi.php?type=delete_option&id=${id}`)
            .then((res) => {
                getOptionList()
            }, error => {
                alert(error);
            });
    }

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Toast show={displayToast} onClose={() => { enableToastDisplay(false) }}>
                    <Toast.Header>
                        <strong className="me-auto">Option updated successfully.</strong>
                    </Toast.Header>
                </Toast>
                <Stack gap={2}>
                    {optionList && Object.keys(optionList).map((category) =>
                        <>
                            <strong className="me-auto">{category}</strong>
                            {optionList[category].map((item) =>
                                <Stack key={item.id} gap={2} direction="horizontal">
                                    <label style={{ width: '300px' }}>{item.name}</label>
                                    <Button variant="danger" onClick={() => { navigate(`/option/${item.id}`) }}>Edit</Button>
                                    <Button variant="danger" onClick={() => { deleteOption(item.id) }}>Delete</Button>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </Container>
        </div>
    );
}

export default ManageOptions;