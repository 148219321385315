import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { Component }  from 'react';
import IconList from '../admin/iconList';

function AddCustomOption(props) {
    const [name, setName] = useState("")
    const [selectedIconId, setSelectedIconId] = useState("")
    useEffect(() => {
        
    }, []);

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/options`;
        const data = {
            name: name,
            category: "custom",
            type: "custom",
            custom_section_id: props.customSection.id
        };
        if (selectedIconId && typeof selectedIconId === 'number') {
            data.icon_id = selectedIconId
        }
        axios
            .post(url, data, {})
            .then((res) => {
                props.completionHandler();
            }, error => {
                alert(error);
            });
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Option
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" onChange={(event) => { setName(event.target.value) }} />
                    </Form.Group>
                    { props.customSection &&  props.customSection.enable_icon_selection &&
                        <IconList onSelectedIconChange={(iconId) => { setSelectedIconId(iconId) }}/>
                    }
                    <Button variant="primary" type="button" onClick={onSubmitHandler}>
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCustomOption;