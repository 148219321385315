import axios from 'axios';
import { useEffect, useState } from 'react';
import { Form, Image, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { Component }  from 'react';

function AddTag(props) {
    const [tags, setTags] = useState([]);
    const [name, setName] = useState([]);

    function refreshTags() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/tags`
        axios
            .get(url)
            .then((result) => {
                setTags(result.data.records);
            }, error => {
                alert(error);
            });
    }

    function onSubmitHandler() {
        let url = `${process.env.REACT_APP_API_URL}index.php/records/tags`;
        const data = {
            name: name
        };
        axios
            .post(url, data, {})
            .then((res) => {
                setName("")
                refreshTags();
            }, error => {
                alert(error);
            });
    }

    function onNameChange(event) {
        setName(event.target.value)
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            onShow={refreshTags}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tags
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack direction="horizontal" gap={3}>
                        {tags && tags.map((tag) => {
                            return (
                                <Button key={tag.name} variant="secondary" onClick={() => { props.onTagSelected(tag) }}>{tag.name}</Button>
                            )
                        })}
                    </Stack>

                    <Stack gap={3} className="col-md-5 mt-2" >
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} placeholder="Enter name" onChange={onNameChange} />
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={onSubmitHandler}>
                            Add New
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddTag;