import React from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: ""
        };
        this.onCompletionHandler = props.onCompletionHandler
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files
        });
    }

    onSubmit() {
        const data = new FormData();

        for (let i = 0; i < this.state.selectedFile.length; i++) {
            data.append("file[]", this.state.selectedFile[i]);
        }
        let url = `${process.env.REACT_APP_API_URL}server/upload.php`;

        axios
            .post(url, data, {})
            .then((res) => {
                this.onCompletionHandler(res.data)
                this.resetFile();
            }, error => {
                alert(error);
            });

        return false;
    }

    resetFile() {
        document.getElementsByName("file")[0].value = null;
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Select File :</label>
                            <input
                                type="file"
                                className="form-control"
                                multiple
                                name="file"
                                onChange={this.handleInputChange}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="form-row">
                        <div className="col-md-6">
                            <Button
                                variant="primary"
                                onClick={this.onSubmit.bind(this)}
                            >
                                Upload File
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileUploader;