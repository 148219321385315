import axios from "axios";
import React, { forwardRef, Fragment, useEffect, useState } from "react"
import { Button, Col, Image, Row, Stack, Table } from "react-bootstrap";

import * as dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EntryListing from "./entryListing";
var isToday = require('dayjs/plugin/isToday')

dayjs.extend(isToday)

function Calender() {
    const [ratings, setRatings] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState("");
    const [entries, setEntries] = useState("");

    const DateSwitcher = forwardRef(({ value, onClick }, ref) => (
        <Button style={{ marginLeft: '50px' }} onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    const ratingColors = ["#FF0000", "#FFFF00", "#5d0d80", "#132fd1", "#147a80", "#32a852"]

    useEffect(() => {
        loadCalendar(startDate)
    }, [])

    useEffect(() => {
        loadCalendar(startDate)
    }, [startDate])

    function loadCalendar(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        const labels = Array.from({length: lastDayOfMonth.date()}, (_, i) => i + 1);
        labels.map((key) => {
            ratings[key] = 0;
        });
        console.log("labelslabels", labels);
        setRatings(ratings)
        getAverageRating(date)
    }

    function getAverageRating(date) {
        const firstDayOfMonth = dayjs(date).startOf('month');
        const lastDayOfMonth = dayjs(date).endOf('month');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}`;
        const updatedRatings = { ...ratings };
        axios.get(url).then(result => {
            result.data.records.map((item) => {
                const dayOfMonth = dayjs(item.added_on * 1000).date()
                let dateRating = updatedRatings[dayOfMonth] || 0
                updatedRatings[dayOfMonth] = (dateRating + item.rating) / (dateRating > 0 ? 2 : 1)
            });
            const ratingsData = {
                ...ratings,
                ...updatedRatings
            };
            setRatings(ratingsData);
        });
    }

    function getEntries(date) {
        console.log("getEntriesgetEntries", date);
        setSelectedDate(date);
        const firstDayOfMonth = dayjs(date).startOf('day');
        const lastDayOfMonth = dayjs(date).endOf('day');
        let url = `${process.env.REACT_APP_API_URL}index.php/records/record_entry?filter=added_on,bt,${firstDayOfMonth.unix()},${lastDayOfMonth.unix()}&join=activity_options,options&join=activity_tags,tags`;
        axios.get(url).then((result) => {
            if (result.data.records) {
                setEntries(result.data.records)
            }
        });
    }

    function onDateChange(date) {
        console.log(date, startDate)
        setRatings({})
        setEntries([])
        setStartDate(date)
    }

    function spliceIntoChunks(daysAA, chunkSize) {
        const arr = Object.keys(daysAA);
        const res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }

        return res;
    }

    function refreshEntries(day) {
        getEntries(dayjs(startDate).startOf('month').add(day - 1, 'day'))
    }


    return (
        <>
            <Stack direction="horizontal" className="m-5" gap={5}>
                <Button variant="secondary" onClick={() => { const date = dayjs(startDate).subtract(1, 'month'); onDateChange(date); }}>Prev</Button>
                {dayjs(startDate).format('MMM')}
                <Button disabled={dayjs(startDate).month() == dayjs().month()} variant="secondary" onClick={() => { const date = dayjs(startDate).add(1, 'month'); onDateChange(date); }}>Next</Button>
            </Stack>
            <h5 className="m-5">Rating Color Chart</h5>
            <Stack direction="horizontal" className="m-5">
                {ratingColors.map((item, index) =>
                    <div key={index} className="border p-3" style={{ backgroundColor: item }}>{index}</div>
                )}
            </Stack>
            <Table striped bordered className="m-5">
                <tbody>
                    {ratings && spliceIntoChunks(ratings, 7).map((result, key) =>
                        <tr key={key}>
                            {result.map((item) =>
                                <td key={item} onClick={() => { refreshEntries(item) }} style={{ cursor: "pointer", backgroundColor: ratingColors[Math.round(ratings[item])] }}>{item}</td>
                            )}
                        </tr>
                    )}
                </tbody>
            </Table>
            <Stack direction="horizontal" gap={5} className="px-5">
                <Button variant="secondary" onClick={() => { const date = dayjs(selectedDate).subtract(1, 'day'); getEntries(date); }}>Prev</Button>
                <Button disabled={dayjs(selectedDate).isToday()} variant="secondary" onClick={() => { const date = dayjs(selectedDate).add(1, 'day'); getEntries(date); }}>Next</Button>
            </Stack>
            <EntryListing entries={entries} className="m-5" />
        </>
    )
}

export default Calender